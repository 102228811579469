import React, { FunctionComponent, useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { TableRow, TableCell, Box, Typography, useTheme, useMediaQuery, Grid } from '@material-ui/core';
import clsx from 'clsx';
import Appointment from '@spike/appointment-model';
import { DateTimeFormat, PetAvatar, PriceFormat, ShortDateTimeFormat } from 'components/UI';
import Staff from './Staff';
import PetTypeComponent from './PetTypeComponent';
import { AppointmentButton, AppointmentOptions } from 'components/UI';
import { AvailableStaff } from '@spike/available-staff-model';
import { BookingsView } from '@spike/bookings-view';
import { PetName } from './PetName';
import { status } from '@spike/appointment-model';

import { faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomerName from './CustomerName';

interface Props {
    appointment: Appointment;
    view: BookingsView;
    viewClient?: boolean;
    onShow: () => void;
    onAssignStaff: (member: AvailableStaff | undefined) => void;
    onCheckIn: () => void;
    onStart: () => void;
    onComplete: () => void;
    onCheckout: () => void;
    onShowReport: () => void;
    onCancel: () => void;
    onNoShow: () => void;
    onShowInvoice: () => void;
    onShowPet: (petId: number) => void;
    onShowClient: (clientId: number) => void;
    onReschedule: () => void;
    onBook: (clientId: number, petId: number, name: string) => void;
    onDecline: () => void;
    onUndo: (appointmentId: number, statusId: number) => void;
    onShowAddReport: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        row: {
            'cursor': 'pointer',
            '&:hover': {
                backgroundColor: '#FAFAFA !important'
            }
        },
        cell: {
            paddingLeft: '0px',
            borderBottom: '2px solid #EFEFEF',
            [theme.breakpoints.down('sm')]: {
                fontSize: '11px',
                paddingTop: '8px',
                paddingBottom: '8px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '13px',
                paddingTop: '13px',
                paddingBottom: '13px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '14px',
                paddingTop: '17px',
                paddingBottom: '17px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '18px',
                paddingTop: '26px',
                paddingBottom: '26px'
            }
        },
        firstCell: {
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '27px'
            },
            [theme.breakpoints.only('md')]: {
                paddingLeft: '43px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingLeft: '57px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingLeft: '87px'
            }
        },
        cellDate: {
            [theme.breakpoints.between(768, 1025)]: {
                width: '140px'
            }
        },
        cellStatus: {
            width: '150px',
            [theme.breakpoints.down('md')]: {
                width: '130px'
            },
            [theme.breakpoints.only('md')]: {
                width: '130px'
            },
            [theme.breakpoints.up('xl')]: {
                width: '200px'
            }
        },
        confirmed: {
            color: '#888888'
        },
        unasignnedMessageContainer: {
            display: 'flex',
            position: 'absolute',
            background: '#ffffff',
            zIndex: 300,
            border: '1px solid #D3D3D3',
            [theme.breakpoints.down('sm')]: {
                borderRadius: '2px',
                padding: '5px',
                width: '60px',
                height: '29px',
                marginTop: '7px'
            },
            [theme.breakpoints.only('md')]: {
                borderRadius: '3px',
                paddingLeft: '8px',
                paddingTop: '3px',
                width: '116px',
                height: '47px',
                marginTop: '10px',
                marginLeft: '-20px'
            },
            [theme.breakpoints.only('lg')]: {
                borderRadius: '4px',
                paddingLeft: '11px',
                paddingTop: '5px',
                width: '140px',
                height: '63px',
                marginTop: '13px',
                marginLeft: '-20px'
            },
            [theme.breakpoints.only('xl')]: {
                borderRadius: '6px',
                padding: '16px',
                width: '184px',
                height: '95px',
                marginTop: '20px',
                marginLeft: '-18px'
            }
        },
        unasignnedMessage: {
            [theme.breakpoints.down('sm')]: {
                fontSize: '7px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '9px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '11px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '14px'
            }
        },
        appointmentButton: {
            '& .MuiButton-root': {
                color: '#fff',
                backgroundColor: '#D3D3D399'
            },
            '& .MuiButton-outlined': {
                border: 'none !important'
            }
        },
        mobileItemCell: {
            padding: '0px 0px 24px'
        },
        mobileItem: {
            borderRadius: 14,
            padding: '0px 16px',
            border: 'solid 1px #D4D4D4'
        },
        mobileItemHeader: {
            gap: 10,
            display: 'flex',
            flexWrap: 'wrap',
            padding: '14px 0px 11px',
            justifyContent: 'space-between'
        },
        mobileItemPet: {
            gap: 6,
            flex: 1,
            display: 'flex',
            alignItems: 'center'
        },
        mobileItemAvatar: {
            'width': 44,
            'height': 44,
            'flex': '0 0 44px',

            '& svg': {
                width: '27px !important',
                height: '27px !important'
            }
        },
        mobileItemPetBreed: {
            fontSize: 13,
            lineHeight: 1,
            fontWeight: 400,
            color: '#707070',
            marginTop: 4
        },
        mobileItemDate: {
            fontSize: 13,
            lineHeight: 1,
            fontWeight: 500,
            paddingTop: 6
        },
        mobileItemBody: {
            gap: 6,
            display: 'grid',
            gridTemplateColumns: '1fr min-content',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '8px 0px',
            borderTop: 'solid 1px #D4D4D4',
            borderBottom: 'solid 1px #D4D4D4'
        },
        mobileItemService: {
            flex: 1,
            fontSize: 14,
            lineHeight: 1.4,
            display: 'block',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis'
        },
        mobileItemFooter: {
            'fontSize': 14,
            'lineHeight': 1,
            'fontWeight': 600,
            'padding': '20px 0px',

            '& button': {
                'height': 40,
                'width': '100%',

                '& span': {
                    fontSize: '14px !important',
                    fontWeight: '600 !important'
                }
            }
        },
        mobileItemButton: {
            'height': 40,
            'width': '100%',

            '& span': {
                fontSize: '14px !important',
                fontWeight: '600 !important'
            }
        },
        boxStatus: {
            display: 'flex',
            flexDirection: 'row'
        },
        boxUndo: {
            padding: '1px',
            marginLeft: '18px',
            [theme.breakpoints.down('sm')]: {
                marginLeft: '10px'
            },
            [theme.breakpoints.only('md')]: {
                marginLeft: '10px'
            },
            [theme.breakpoints.up('xl')]: {
                marginLeft: '20px',
                paddingTop: '8px'
            }
        },
        undoIcon: {
            marginTop: '7px'
        },
        undoIconStart: { color: '#D3D3D3' },
        undoIconComplete: { color: '#92B4A7' },
        undoIconCheckOut: { color: '#222222' }
    })
);

export const AppointmentRow: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const theme = useTheme();

    const isDesktop = useMediaQuery(theme.breakpoints.up(1025));
    const isMobile = useMediaQuery(theme.breakpoints.down(768));

    const [isUndo, setIsUndo] = useState(false);
    const [isUndoStart, setIsUndoStart] = useState(false);
    const [isUndoComplete, setIsUndoComplete] = useState(false);
    const [isUndoCheckOut, setIsUndoCheckOut] = useState(false);
    const [showUndo, setShowUndo] = useState(false);
    const [showUndoHover, setShowUndoHover] = useState(false);
    const [showStaffAlert, setShowStaffAlert] = useState(false);

    useEffect(() => {
        switch (props.appointment.status.id) {
            case status.CHECKED_IN:
                setShowUndo(true);
                setIsUndoStart(true);
                setIsUndoComplete(false);
                break;
            case status.IN_PROGRESS:
                setShowUndo(true);
                setIsUndoComplete(true);
                setIsUndoCheckOut(false);
                break;
            case status.READY_FOR_CHECK_OUT:
                setIsUndoCheckOut(true);
                //props.appointment.services.filter((service) => service.hasReport === true).length === 0 ? setShowUndo(true) : setShowUndo(false);
                setShowUndo(true);
                break;
            default:
                setShowUndo(false);
                break;
        }
        setIsUndo(false);
    }, [props.appointment.status.id]);

    const showHandler = () => {
        props.onShow && props.onShow();
    };

    const camelize = (str: string) => {
        return str.replace(/\b(\w)/g, s => s.toUpperCase());
    };

    const mobileAppointmentItem = (
        <TableRow>
            <TableCell className={classes.mobileItemCell} onClick={props.onShow}>
                <Grid container className={classes.mobileItem}>
                    <Grid item xs={12} className={classes.mobileItemHeader}>
                        <Box className={classes.mobileItemPet}>
                            <PetAvatar
                                petType={props.appointment.pet.type}
                                imageUrl={props.appointment.pet.image}
                                className={classes.mobileItemAvatar}
                            />

                            <Box>
                                <PetName
                                    bold={true}
                                    name={props.appointment.pet?.name}
                                    hasMedicalConditions={props.appointment.pet.hasMedicalConditions}
                                    onClick={() => props.onShowPet(props.appointment.pet.id)}
                                />
                                <Typography className={classes.mobileItemPetBreed}>
                                    {props.appointment.pet.breed?.name}
                                </Typography>
                            </Box>
                        </Box>

                        <Box className={classes.mobileItemDate}>
                            <ShortDateTimeFormat date={props.appointment.duration.from} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} className={classes.mobileItemBody}>
                        <Typography className={classes.mobileItemService}>
                            {props.appointment.services[0].name}
                        </Typography>
                        <Staff
                            appointment={props.appointment}
                            onAssignStaff={(appointmentUuid, staff) => props.onAssignStaff(staff)}
                        />
                    </Grid>
                    <Grid item xs={12} className={classes.mobileItemFooter}>
                        <AppointmentButton
                            id="booking_table"
                            appointment={props.appointment}
                            isUndo={isUndo}
                            activeView={props.view.id}
                            //
                            onCheckIn={props.appointment.services[0].staff === undefined ? () => null : props.onCheckIn}
                            onStart={props.appointment.services[0].staff === undefined ? () => null : props.onStart}
                            onComplete={
                                props.appointment.services[0].staff === undefined ? () => null : props.onComplete
                            }
                            onCheckOut={props.onCheckout}
                            onShowDetails={showHandler}
                            showCancelationReason
                        />
                    </Grid>
                </Grid>
            </TableCell>
        </TableRow>
    );

    const undoHandler = () => {
        switch (props.appointment.status.id) {
            case status.CHECKED_IN:
                setIsUndo(true);
                props.onUndo(props.appointment.id!, status.CONFIRMED);
                break;
            case status.IN_PROGRESS:
                setIsUndo(true);
                props.onUndo(props.appointment.id!, status.CHECKED_IN);
                break;
            case status.READY_FOR_CHECK_OUT:
                setIsUndo(true);
                props.onUndo(props.appointment.id!, status.IN_PROGRESS);
                break;
            default:
                break;
        }
    };

    const undoView = (
        <Typography>
            <FontAwesomeIcon
                icon={faUndo}
                className={clsx(
                    { [classes.undoIcon]: true },
                    { [classes.undoIconStart]: isUndoStart },
                    { [classes.undoIconComplete]: isUndoComplete },
                    { [classes.undoIconCheckOut]: isUndoCheckOut }
                )}
                size="1x"
            ></FontAwesomeIcon>
        </Typography>
    );

    const appointmentTableRow = (
        <TableRow
            className={classes.row}
            onMouseEnter={() => setShowUndoHover(true)}
            onMouseLeave={() => setShowUndoHover(false)}
        >
            <TableCell className={clsx(classes.cell, classes.firstCell)} onClick={props.onShow}>
                <CustomerName
                    name={props.appointment.customer.firstName.concat(' ').concat(props.appointment.customer.lastName)}
                    hasMedicalConditions={false}
                    onClick={() => props.onShowClient(props.appointment.customer.id)}
                />
            </TableCell>
            <TableCell className={classes.cell} onClick={props.onShow}>
                <PetName
                    name={props.appointment.pet?.name}
                    hasMedicalConditions={props.appointment.pet.hasMedicalConditions}
                    onClick={() => props.onShowPet(props.appointment.pet.id)}
                />
            </TableCell>
            {isDesktop ? (
                <>
                    <TableCell className={classes.cell} onClick={props.onShow}>
                        {props.appointment.pet.breed?.name}
                    </TableCell>
                </>
            ) : null}
            <TableCell className={classes.cell} onClick={props.onShow}>
                <PetTypeComponent petType={props.appointment.pet.type} />
            </TableCell>
            <TableCell className={classes.cell} onClick={props.onShow}>
                {props.appointment.services[0].name}
            </TableCell>
            <TableCell className={classes.cell} onClick={props.onShow}>
                <Staff
                    appointment={props.appointment}
                    onAssignStaff={(appointmentUuid, staff) => props.onAssignStaff(staff)}
                />
            </TableCell>
            <TableCell className={clsx([classes.cell, classes.cellDate])} onClick={props.onShow}>
                <DateTimeFormat date={props.appointment.duration.from} />
            </TableCell>
            {isDesktop ? (
                <TableCell className={classes.cell} onClick={props.onShow}>
                    <PriceFormat price={props.appointment.total} />
                </TableCell>
            ) : null}
            <TableCell className={clsx(classes.cell, classes.cellStatus)}>
                <Box className={classes.boxStatus}>
                    <Box
                        className={
                            props.appointment.services[0].staff === undefined &&
                            (props.appointment.status.id === status.CONFIRMED ||
                                props.appointment.status.id === status.CHECKED_IN ||
                                props.appointment.status.id === status.IN_PROGRESS)
                                ? classes.appointmentButton
                                : undefined
                        }
                        onMouseEnter={() => setShowStaffAlert(true)}
                        onMouseLeave={() => setShowStaffAlert(false)}
                    >
                        <AppointmentButton
                            id="booking_table"
                            appointment={props.appointment}
                            isUndo={isUndo}
                            activeView={props.view.id}
                            //
                            onCheckIn={props.appointment.services[0].staff === undefined ? () => null : props.onCheckIn}
                            onStart={props.appointment.services[0].staff === undefined ? () => null : props.onStart}
                            onComplete={
                                props.appointment.services[0].staff === undefined ? () => null : props.onComplete
                            }
                            onCheckOut={props.onCheckout}
                            onShowDetails={showHandler}
                            showCancelationReason
                        />
                    </Box>
                    <Box className={classes.boxUndo} onClick={undoHandler}>
                        {showUndo && showUndoHover && undoView}
                    </Box>
                </Box>
                {showStaffAlert && props.appointment.services[0].staff === undefined && (
                    <Box className={classes.unasignnedMessageContainer}>
                        <Typography className={classes.unasignnedMessage}>
                            {props.appointment.status.id === status.CONFIRMED &&
                                'Please assign a staff member to check in the appointment'}
                            {props.appointment.status.id === status.CHECKED_IN &&
                                'Please assign a staff member to start the appointment'}
                            {props.appointment.status.id === status.IN_PROGRESS &&
                                'Please assign a staff member to complete the appointment'}
                        </Typography>
                    </Box>
                )}
            </TableCell>
            {isDesktop ? (
                <TableCell className={classes.cell}>
                    <AppointmentOptions
                        appointment={props.appointment}
                        customerDeleted={props.appointment?.customer?.deleted}
                        showUndo={showUndo}
                        onReschedule={props.onReschedule}
                        onCancel={props.onCancel}
                        onNoShow={props.onNoShow}
                        onShowReport={props.onShowReport}
                        onShowInvoice={props.onShowInvoice}
                        onBook={() =>
                            props.onBook(
                                props.appointment.customer.id,
                                props.appointment.pet.id,
                                props.appointment.customer.firstName + ' ' + props.appointment.customer.lastName
                            )
                        }
                        onDecline={props.onDecline}
                        onUndo={undoHandler}
                        onShowAddReport={props.onShowAddReport}
                    />
                </TableCell>
            ) : null}
        </TableRow>
    );

    const appointmentClientTableRow = (
        <TableRow
            className={classes.row}
            onMouseEnter={() => setShowUndoHover(true)}
            onMouseLeave={() => setShowUndoHover(false)}
        >
            <TableCell className={clsx(classes.cell, classes.firstCell)} onClick={props.onShow}>
                {props.appointment.services[0].name}
            </TableCell>
            <TableCell className={classes.cell} onClick={props.onShow}>
                <PetName
                    name={camelize(props.appointment.pet?.name)}
                    hasMedicalConditions={props.appointment.pet.hasMedicalConditions}
                    onClick={() => props.onShowPet(props.appointment.pet.id)}
                />
            </TableCell>
            <TableCell className={classes.cell} onClick={showHandler}>
                <DateTimeFormat date={props.appointment.duration.from} />
            </TableCell>
            <TableCell className={classes.cell} onClick={showHandler}>
                <Staff
                    appointment={props.appointment}
                    onAssignStaff={(appointmentUuid, staff) => props.onAssignStaff(staff)}
                />
            </TableCell>
            <TableCell className={classes.cell} onClick={showHandler}>
                <PriceFormat price={props.appointment.total} />
            </TableCell>
            <TableCell className={clsx(classes.cell, classes.cellStatus)}>
                <Box className={classes.boxStatus}>
                    <Box
                        className={
                            props.appointment.services[0].staff === undefined &&
                            (props.appointment.status.id === status.CONFIRMED ||
                                props.appointment.status.id === status.CHECKED_IN ||
                                props.appointment.status.id === status.IN_PROGRESS)
                                ? classes.appointmentButton
                                : undefined
                        }
                        onMouseEnter={() => setShowStaffAlert(true)}
                        onMouseLeave={() => setShowStaffAlert(false)}
                    >
                        <AppointmentButton
                            id="booking_table"
                            appointment={props.appointment}
                            isUndo={isUndo}
                            activeView={props.view.id}
                            onCheckIn={props.appointment.services[0].staff === undefined ? () => null : props.onCheckIn}
                            onStart={props.appointment.services[0].staff === undefined ? () => null : props.onStart}
                            onComplete={
                                props.appointment.services[0].staff === undefined ? () => null : props.onComplete
                            }
                            onCheckOut={props.onCheckout}
                            onShowDetails={showHandler}
                            showCancelationReason
                        />
                    </Box>
                    <Box className={classes.boxUndo} onClick={undoHandler}>
                        {showUndo && showUndoHover && undoView}
                    </Box>
                </Box>
                {showStaffAlert && props.appointment.services[0].staff === undefined && (
                    <Box className={classes.unasignnedMessageContainer}>
                        <Typography className={classes.unasignnedMessage}>
                            {props.appointment.status.id === status.CONFIRMED &&
                                'Please assign a staff member to check in the appointment'}
                            {props.appointment.status.id === status.CHECKED_IN &&
                                'Please assign a staff member to start the appointment'}
                            {props.appointment.status.id === status.IN_PROGRESS &&
                                'Please assign a staff member to complete the appointment'}
                        </Typography>
                    </Box>
                )}
            </TableCell>
            {isDesktop ? (
                <TableCell className={classes.cell}>
                    <AppointmentOptions
                        appointment={props.appointment}
                        showUndo={showUndo}
                        customerDeleted={props.appointment?.customer?.deleted}
                        onReschedule={props.onReschedule}
                        onCancel={props.onCancel}
                        onNoShow={props.onNoShow}
                        onShowReport={props.onShowReport}
                        onShowInvoice={props.onShowInvoice}
                        onBook={() =>
                            props.onBook(
                                props.appointment.customer.id,
                                props.appointment.pet.id,
                                props.appointment.customer.firstName + ' ' + props.appointment.customer.lastName
                            )
                        }
                        onDecline={props.onDecline}
                        onUndo={undoHandler}
                        onShowAddReport={props.onShowAddReport && props.onShowAddReport}
                    />
                </TableCell>
            ) : null}
        </TableRow>
    );

    return isMobile ? mobileAppointmentItem : props.viewClient ? appointmentClientTableRow : appointmentTableRow;
};

export default AppointmentRow;
