import React, { FunctionComponent, useState } from 'react';
import {
    Box,
    ClickAwayListener,
    FormControlLabel,
    Radio,
    RadioGroup,
    useMediaQuery,
    useTheme
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import SelectionBarItem from './SelectionBarItem';
import clsx from 'clsx';

interface Props {
    options: Array<SelectionBarOption>;
    selectedOptionId: string;
    onSelect: (id: string) => void;
    count?: number;
    id?: string;
    mobileBreakpoint?: number;
}

export interface SelectionBarOption {
    id: string;
    name: string;
    mark?: Mark;
    count?: number;
    selectedBackgroundColor?: string;
}

export enum Mark {
    WARNING = 'warning',
    ERROR = 'error'
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            background: '#F8F8F8',
            border: 'none',
            height: 'fit-content',
            [theme.breakpoints.down('md')]: {
                borderRadius: '100px',
                padding: 0
            },
            [theme.breakpoints.between('md', 'lg')]: {
                borderRadius: '100px',
                paddingLeft: '6px',
                paddingRight: '6px',
                paddingTop: '4px',
                paddingBottom: '4px'
            },
            [theme.breakpoints.up('xl')]: {
                borderRadius: '100px',
                paddingLeft: '8px',
                paddingRight: '8px',
                paddingTop: '5px',
                paddingBottom: '5px'
            }
        },
        button: {
            marginLeft: 14,
            marginRight: 14,

            [theme.breakpoints.down('lg')]: {
                margin: '0px 7px'
            },
            [theme.breakpoints.down('md')]: {
                margin: 0
            }
        },
        firstButton: {
            marginLeft: '0px'
        },
        lastButton: {
            marginRight: '0px'
        },
        backdrop: {
            [theme.breakpoints.down('md')]: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 9999,
                display: 'flex',
                alignItems: 'flex-end',
                position: 'fixed',
                backgroundColor: 'rgba(0, 0, 0, 0.25)'
            }
        },
        mobileContainer: {
            flex: '0 0 100%',
            paddingTop: 20,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 32,
            backgroundColor: '#fff',
            borderRadius: '20px 20px 0px 0px',
            boxShadow: '0px -10px 40px rgba(0, 0, 0, 0.0784314)'
        },
        mobilePopupChip: {
            width: 56,
            height: 6,
            display: 'block',
            borderRadius: 100,
            margin: '0px auto 15px',
            backgroundColor: '#D4D4D4'
        },
        mobileItem: {
            '&:not(:last-child)': {
                marginBottom: 20
            },
            '& .MuiRadio-root': {
                'paddingTop': 0,
                'paddingBottom': 0,

                '& svg': {
                    color: '#000'
                }
            },
            '& .MuiTypography-root': {
                fontSize: 14,
                lineHeight: 1.7
            },
            '& .MuiIconButton-label': {
                color: '#92B4A7'
            }
        }
    })
);

export const SelectionBar: FunctionComponent<Props> = props => {
    const classes = useStyles(props);
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down(props.mobileBreakpoint || 800));

    const [openPopup, setOpenPopup] = useState<boolean>(false);

    const clickHandler = (id: string) => {
        if (props.selectedOptionId === id && isMobile) setOpenPopup(true);

        if (props.selectedOptionId !== id) {
            props.onSelect(id);
            isMobile && setOpenPopup(false);
        }
    };

    const desktopButtons = props.options.map((option, index) => {
        const buttonStyle = clsx(classes.button, {
            [classes.firstButton]: index === 0,
            [classes.lastButton]: props.options.length === 1 || index + 1 === props.options.length
        });

        return (
            <Box key={option.id}>
                <SelectionBarItem
                    id={`${props.id || ''}_section_item`}
                    label={option.name}
                    selected={option.id === props.selectedOptionId}
                    selectedBackgroundColor={option.selectedBackgroundColor}
                    onClick={() => {
                        clickHandler(option.id);
                    }}
                    mark={option.mark}
                    className={buttonStyle}
                    count={props.count}
                />
            </Box>
        );
    });

    const mobileButton = props.options
        .filter((option, index) => option.id === props.selectedOptionId)
        .map((option, index) => {
            const buttonStyle = clsx(classes.button, {
                [classes.firstButton]: index === 0,
                [classes.lastButton]: props.options.length === 1 || index + 1 === props.options.length
            });

            return (
                <Box key={option.id}>
                    <SelectionBarItem
                        label={option.name}
                        selected={option.id === props.selectedOptionId}
                        selectedBackgroundColor={option.selectedBackgroundColor}
                        onClick={() => {
                            clickHandler(option.id);
                        }}
                        mark={option.mark}
                        className={buttonStyle}
                        count={props.count}
                    />
                </Box>
            );
        });

    const mobilePopup = (
        <Box className={classes.backdrop}>
            <ClickAwayListener onClickAway={() => setOpenPopup(false)}>
                <Box className={classes.mobileContainer}>
                    <span className={classes.mobilePopupChip} onClick={() => setOpenPopup(false)}></span>

                    <RadioGroup
                        name="selection"
                        value={props.selectedOptionId}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => clickHandler(e.target.value)}
                    >
                        {props.options
                            .filter((option, index) => option.id === 'all')
                            .map((option, index) => (
                                <FormControlLabel
                                    className={classes.mobileItem}
                                    value={option.id}
                                    control={<Radio />}
                                    label={option.name}
                                    key={index}
                                />
                            ))}

                        {props.options
                            .filter((option, index) => option.id !== 'all')
                            .map((option, index) => (
                                <FormControlLabel
                                    className={classes.mobileItem}
                                    value={option.id}
                                    control={<Radio />}
                                    label={option.name}
                                    key={index}
                                />
                            ))}
                    </RadioGroup>
                </Box>
            </ClickAwayListener>
        </Box>
    );

    return (
        <Box className={classes.container}>
            {isMobile ? mobileButton : desktopButtons}
            {openPopup && mobilePopup}
        </Box>
    );
};

export default SelectionBar;
