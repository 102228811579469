import { MarketplaceOnlineBooking, MarketplaceTimeLimit } from '@spike/marketplace-model';
import { Box, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { reduceResolution, wbp } from 'Theme';
import clsx from 'clsx';
import { FunctionComponent, useState } from 'react';
import { useApiClientWrapper, useMasterData } from 'hooks';
import { useDispatch } from 'react-redux';
import useNonInitialEffect from '@versiondos/hooks';
import {
    saveOlbAllowNewClientsThunk,
    saveOlbAllowSelectStaffThunk,
    saveOlbBookingRequestThunk,
    saveOlbLimitsThunk
} from '@spike/marketplace-action';
import { FieldError } from '@spike/model';
import isEmpty from 'lodash/isEmpty';
import Section from './Section';
import Limit from './Limit';
import RowSwitch from './RowSwitch';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            paddingTop: '36px'
        },
        sectionRow: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(22)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '22px'
            }
        },
        sectionContainer: {
            borderTop: 'none',
            paddingTop: 'unset',
            marginTop: 'unset'
        },
        radioDescription: {
            color: '#7A7A7A',
            fontSize: '14px',
            lineHeight: '19.6px',
            paddingLeft: '31px'
        },
        radioContainer: {
            paddingTop: '24px'
        },
        optionContainer: {
            [theme.breakpoints.down('md')]: {
                marginBottom: '16px'
            },
            [theme.breakpoints.up('md')]: {
                marginRight: '32px'
            }
        },
        radioGroup: {
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column'
            }
        }
    })
);

const validate = (from: MarketplaceTimeLimit | null, to: MarketplaceTimeLimit | null): Array<FieldError> => {
    const errors: Array<FieldError> = [];

    if (
        from !== null &&
        from.offsetSeconds !== null &&
        to !== null &&
        to.offsetSeconds !== null &&
        from.offsetSeconds > to.offsetSeconds
    ) {
        errors.push({ fieldName: 'from', errorMessage: `Is after ${to.name}` });
        errors.push({
            fieldName: 'to',
            errorMessage: `Is before ${from.name}`
        });
    }

    return errors;
};

export interface Props {
    onlineBooking: MarketplaceOnlineBooking;
    className?: string;
    isAdmin?: boolean;
    isMobile?: boolean;
    onSaveMarketplace: (pricingOption: string) => void;
}

export const Settings: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const masterData = useMasterData();
    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const [errors, setErrors] = useState<Array<FieldError>>([]);
    const [limits, setLimits] = useState({
        from: props.onlineBooking.from,
        to: props.onlineBooking.to
    });
    const [pricingOption, setPricingOption] = useState<string>(props.onlineBooking.pricingOption);

    useNonInitialEffect(() => {
        setLimits({
            from: props.onlineBooking.from,
            to: props.onlineBooking.to
        });
    }, [props.onlineBooking]);

    const selectFromHandler = (from: MarketplaceTimeLimit | null) => {
        setLimits(prev => ({ ...prev, from }));
        const errors = validate(from, limits.to);
        setErrors(errors);
        isEmpty(errors) && dispatch(saveOlbLimitsThunk(apiClientWrapper, from, props.onlineBooking.to));
    };

    const selectToHandler = (to: MarketplaceTimeLimit | null) => {
        setLimits(prev => ({ ...prev, to }));
        const errors = validate(limits.from, to);
        setErrors(errors);
        isEmpty(errors) && dispatch(saveOlbLimitsThunk(apiClientWrapper, props.onlineBooking.from, to));
    };

    const changeAllowClientsSelectStaffHandler = (checked: boolean) => {
        dispatch(saveOlbAllowSelectStaffThunk(apiClientWrapper, checked));
    };

    const changeAllowNewClientsHandler = (checked: boolean) => {
        dispatch(saveOlbAllowNewClientsThunk(apiClientWrapper, checked));
    };

    const changeBoolingRequestHandler = (checked: boolean) => {
        dispatch(saveOlbBookingRequestThunk(apiClientWrapper, checked));
    };

    const savePricingOption = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPricingOption(event.target.value);
        props.onSaveMarketplace(event.target.value);
    };

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Section
                title="Price Label Display in Online Booking"
                description="Set preferred price label format for client display in Online Booking."
                className={classes.sectionContainer}
            >
                <Box className={classes.radioContainer}>
                    <RadioGroup className={classes.radioGroup} value={pricingOption} onChange={savePricingOption}>
                        <Box className={classes.optionContainer}>
                            <FormControlLabel
                                id="olb_settings_pricing_label_starting_at"
                                value="starting_at"
                                control={<Radio color="default" />}
                                label="Starting At"
                            />
                            <Typography className={classes.radioDescription}>Minimum price that may vary</Typography>
                        </Box>
                        <Box>
                            <FormControlLabel
                                id="business_settings_additional_info_radio_mobile"
                                value="price"
                                control={<Radio color="default" />}
                                label="Price"
                            />
                            <Typography className={classes.radioDescription}>
                                Fixed rate without implying variability
                            </Typography>
                        </Box>
                    </RadioGroup>
                </Box>
            </Section>
            <Section title="Scheduling Rules" description="Set how far in advance your clients can book online.">
                <Limit
                    name="from"
                    limits={masterData.olbFromLimits}
                    selected={limits.from}
                    label="How close to an appointment start time can clients book?"
                    errors={errors}
                    disabled={!props.isAdmin}
                    isMobile={props.isMobile}
                    className={classes.sectionRow}
                    onSelect={selectFromHandler}
                />
                <Limit
                    name="to"
                    limits={masterData.olbToLimits}
                    selected={limits.to}
                    label="How far in advance can clients book appointments?"
                    errors={errors}
                    disabled={!props.isAdmin}
                    isMobile={props.isMobile}
                    className={classes.sectionRow}
                    onSelect={selectToHandler}
                />
                <RowSwitch
                    label="Allow clients to select staff members"
                    checked={props.onlineBooking.allowClientsSelectStaff}
                    name="allowClientsSelectStaff"
                    errors={errors}
                    isMobile={props.isMobile}
                    disabled={!props.isAdmin}
                    onChange={changeAllowClientsSelectStaffHandler}
                    className={classes.sectionRow}
                />
            </Section>
            <Section title="Booking Permissions" description="Set who can make an appointment online.">
                <RowSwitch
                    label="Allow new clients"
                    checked={props.onlineBooking.allowNewClients}
                    name="allowNewClients"
                    errors={errors}
                    isMobile={props.isMobile}
                    disabled={!props.isAdmin}
                    onChange={changeAllowNewClientsHandler}
                    className={classes.sectionRow}
                />
                <RowSwitch
                    label="Review and approve each appointment request manually"
                    checked={props.onlineBooking.bookingRequestsEnabled}
                    name="bookingRequestsEnabled"
                    errors={errors}
                    isMobile={props.isMobile}
                    disabled={!props.isAdmin}
                    onChange={changeBoolingRequestHandler}
                    className={classes.sectionRow}
                />
            </Section>
        </Box>
    );
};

export default Settings;
