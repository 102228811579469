export enum ShowTypes {
    BankAccounts = 'BANK_ACCOUNTS',
    Dashboard = 'DASHBOARD',
    Introduction = 'INTRODUCTION',
    SingleSale = 'SINGLE_SALE',
    SalesReports = 'SALES_REPORTS',
    Terminals = 'TERMINALS',
    TipsDetail = 'TIPS_DETAIL',
    Commissions = 'COMMISSIONS',
    Transactions = 'TRANSACTIONS',
    Transfers = 'TRANSFERS',
    Welcome = 'Welcome',
    ApplicationProcessing = 'APPLICATION_PROCESSING',
    ReviewApplication = 'REVIEW_APPLICATION',
    Setup = 'SETUP',
    NonUs = 'NON_US'
}
