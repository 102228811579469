/* eslint-disable no-unused-vars */
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCat, faDog } from '@fortawesome/free-solid-svg-icons';
import { faCat as faCatLight, faDog as faDogLight } from '@fortawesome/pro-light-svg-icons';
import { Option, PetTypeIds, PetType } from '@spike/model';
import { MasterData } from '@spike/masterdata-model';
import { Marketplace, MarketplacePetTypes } from '@spike/marketplace-model';
import { Status } from 'model';
import Service, { ServicePricing } from 'model/Service';
import { v4 as uuid } from 'uuid';
import { isEmpty, isEqual } from 'lodash';

export interface VariablePriceVariables {
    petTypeVariable: boolean;
    petSizeVariable: boolean;
    hairTypeVariable: boolean;
    hairLengthVariable: boolean;
    exoticTypeVariable: boolean;
}

export interface VariablePriceOption {
    id?: number;
    uuid: string;
    petType?: PetType;
    petSize?: Option<string>;
    hairType?: Option<string>;
    hairLength?: Option<string>;
    exoticType?: Option<string>;
    price?: number;
}

export interface FixedPriceOption {
    id?: number;
    uuid?: string;
    petType: PetType;
    price?: number;
}

export enum SortFields {
    NAME = 'name',
    DURATION = 'duration'
}

export enum SortOrder {
    ASCENDANT = 'ascendant',
    DESCENDANT = 'descendant',
    DEFAULT = 'default'
}

export const fixedPriceOption: Option<string> = {
    id: 'fixed_price',
    name: 'Fixed Price'
};
export const byPetSizePriceOption: Option<string> = {
    id: 'by_pet_size',
    name: 'By Pet Size'
};
export const byHairLenghtPriceOption: Option<string> = {
    id: 'by_hair_length',
    name: 'By Hair Length'
};
export const byPetSizeAndHairLengthPriceOption: Option<string> = {
    id: 'by_pet_size_and_hair_length',
    name: 'By Pet Size & Hair Length'
};

type PricingOptions = Array<Option<string>>;

export const dogsPriceOptions: PricingOptions = [
    fixedPriceOption,
    byPetSizePriceOption,
    byPetSizeAndHairLengthPriceOption
];
export const catsPriceOptions: PricingOptions = [fixedPriceOption, byPetSizePriceOption, byHairLenghtPriceOption];
export const exoticsPriceOptions: PricingOptions = [fixedPriceOption];
export const multiplePetPriceOptions: PricingOptions = [
    fixedPriceOption,
    byPetSizePriceOption,
    byPetSizeAndHairLengthPriceOption
];

export const multiplePetTypeIconMap = new Map<string, IconProp>([
    [PetTypeIds.DOGS, faDog],
    [PetTypeIds.CATS, faCat]
]);
export const uniquePetTypeIconMap = new Map<string, IconProp>([
    [PetTypeIds.DOGS, faDogLight],
    [PetTypeIds.CATS, faCatLight]
]);
export enum PricingPetTypeIds {
    DOGS = 'dogs',
    CATS = 'cats',
    EXOTICS = 'exotics',
    MULTIPLE_PETS = 'multiple_pets'
}

interface PricingOption {
    options: Array<Option<string>>;
    title: string;
    type: string;
}

export const pricingOptionsConfig: Record<PricingPetTypeIds, PricingOption> = {
    [PricingPetTypeIds.DOGS]: { options: dogsPriceOptions, title: 'Price for Dogs', type: 'dogs' },
    [PricingPetTypeIds.CATS]: { options: catsPriceOptions, title: 'Price for Cats', type: 'cats' },
    [PricingPetTypeIds.EXOTICS]: { options: exoticsPriceOptions, title: 'Price for Exotics', type: 'exotics' },
    [PricingPetTypeIds.MULTIPLE_PETS]: {
        options: multiplePetPriceOptions,
        title: 'Price for Multiple Pets',
        type: 'multiple_pets'
    }
};

export const getPricingVariablesOption = (variables: VariablePriceVariables | undefined) => {
    if (variables?.petSizeVariable && variables?.hairLengthVariable) {
        return byPetSizeAndHairLengthPriceOption;
    }
    if (variables?.petSizeVariable) {
        return byPetSizePriceOption;
    }
    if (variables?.hairLengthVariable) {
        return byHairLenghtPriceOption;
    }
    return fixedPriceOption;
};

export const setPricingVariablesOption = (
    option: string,
    petTypes: Array<PetType>
): VariablePriceVariables | undefined => {
    const isExotic = petTypes.some(petType => petType.id === PetTypeIds.EXOTICS);
    const isMutiplePet = petTypes.length > 1;
    if (option === fixedPriceOption.id) {
        return {
            petTypeVariable: isMutiplePet && !isExotic,
            petSizeVariable: false,
            hairTypeVariable: false,
            hairLengthVariable: false,
            exoticTypeVariable: isExotic
        };
    }

    if (option === byPetSizePriceOption.id) {
        return {
            petTypeVariable: isMutiplePet,
            petSizeVariable: true,
            hairTypeVariable: false,
            hairLengthVariable: false,
            exoticTypeVariable: isExotic
        };
    }

    if (option === byPetSizeAndHairLengthPriceOption.id) {
        return {
            petTypeVariable: isMutiplePet,
            petSizeVariable: true,
            hairTypeVariable: false,
            hairLengthVariable: true,
            exoticTypeVariable: isExotic
        };
    }

    if (option === byHairLenghtPriceOption.id) {
        return {
            petTypeVariable: isMutiplePet,
            petSizeVariable: false,
            hairTypeVariable: false,
            hairLengthVariable: true,
            exoticTypeVariable: isExotic
        };
    }
};

export type PricingObject = Record<string, Array<VariablePriceOption | FixedPriceOption>>;

export const initializeFixedPrices = (petTypes: Array<PetType>): Array<FixedPriceOption> => {
    return petTypes.map(petType => ({
        petType,
        price: 0
    }));
};

const initializeVariablePrices = (
    marketplacePetTypes: MarketplacePetTypes,
    selectedPetTypes: Array<PetType>,
    option: Option<string>
): Array<VariablePriceOption> => {
    if (option.id === byPetSizeAndHairLengthPriceOption.id || option.id === byHairLenghtPriceOption.id) {
        return selectedPetTypes.flatMap(petType => {
            const petTypeData = petType.id === PetTypeIds.CATS ? marketplacePetTypes.cats : marketplacePetTypes.dogs;
            return (petTypeData?.sizes ?? []).flatMap(size =>
                (petTypeData?.hairLengths ?? []).map(hairLength => ({
                    uuid: uuid(),
                    petType,
                    petSize: { id: size.element.id, name: size.element.name },
                    hairLength: { id: hairLength.element.id, name: hairLength.element.name },
                    price: 0
                }))
            );
        });
    }
    if (option.id === byPetSizePriceOption.id) {
        return selectedPetTypes.flatMap(petType => {
            const petTypeData = petType.id === PetTypeIds.CATS ? marketplacePetTypes.cats : marketplacePetTypes.dogs;
            return (petTypeData?.sizes ?? []).map(size => ({
                uuid: uuid(),
                petType,
                petSize: { id: size.element.id, name: size.element.name },
                price: 0
            }));
        });
    }

    return [];
};

export const getPricingOptions = (selectedPetTypes: Array<PetType>) => {
    if (selectedPetTypes.length === 0 || !selectedPetTypes[0]) {
        return undefined;
    }
    return selectedPetTypes.length > 1
        ? pricingOptionsConfig[PricingPetTypeIds.MULTIPLE_PETS]
        : pricingOptionsConfig[selectedPetTypes[0].id as PricingPetTypeIds];
};

const mergeVariablePrices = (
    initialPrices: Array<VariablePriceOption>,
    existingPrices: Array<VariablePriceOption>
): Array<VariablePriceOption> => {
    return initialPrices.map(initialPrice => {
        const existingPrice = existingPrices.find(
            existingPrice =>
                existingPrice.petType?.id === initialPrice.petType?.id &&
                existingPrice.petSize?.id === initialPrice.petSize?.id &&
                existingPrice.hairLength?.id === initialPrice.hairLength?.id
        );
        return existingPrice ? { ...initialPrice, price: existingPrice.price, uuid: existingPrice.uuid } : initialPrice;
    });
};

const mergeFixedPrices = (
    initialPrices: Array<FixedPriceOption>,
    existingPrices: Array<FixedPriceOption>
): Array<FixedPriceOption> => {
    return initialPrices.map(initialPrice => {
        const existingPrice = existingPrices.find(
            existingPrice => existingPrice.petType?.id === initialPrice.petType?.id
        );
        return existingPrice ? { ...initialPrice, price: existingPrice.price, uuid: existingPrice.uuid } : initialPrice;
    });
};

export const initializePricesObject = (
    marketplacePetTypes: MarketplacePetTypes,
    selectedPetTypes: Array<PetType>,
    existingPricing?: ServicePricing
): PricingObject => {
    const pricingObject: PricingObject = {};

    const pricingOptions = getPricingOptions(selectedPetTypes);

    if (pricingOptions && pricingOptions.options) {
        pricingOptions.options.forEach(option => {
            if (isEqual(option.id, fixedPriceOption.id)) {
                const initialFixedPrices = initializeFixedPrices(selectedPetTypes);
                pricingObject[option.id] =
                    existingPricing?.fixedPrice && !isEmpty(existingPricing.fixedPrice)
                        ? mergeFixedPrices(initialFixedPrices, existingPricing.fixedPrice)
                        : initialFixedPrices;
            } else {
                const initialVariablePrices = initializeVariablePrices(marketplacePetTypes, selectedPetTypes, option);
                if (existingPricing?.variablePrice?.prices && !isEmpty(existingPricing.variablePrice.prices)) {
                    pricingObject[option.id] = mergeVariablePrices(
                        initialVariablePrices,
                        existingPricing.variablePrice.prices
                    );
                } else {
                    pricingObject[option.id] = initialVariablePrices;
                }
            }
        });
    }
    return pricingObject;
};

export const createEmptyService = (masterData: MasterData, marketplace: Marketplace): Service => {
    return {
        uuid: uuid(),
        businessArea: marketplace.businessAreas[0],
        name: '',
        description: '',
        duration: { simple: { hours: 0, minutes: 0 }, variable: [] },
        pricing: {
            taxable: false,
            taxes: [],
            fixedPrice: [],
            variablePrice: undefined
        },
        petTypes: [],
        staff: [],
        status: masterData.status.find(s => s.id === Status.DRAFT)!,
        tags: [],
        destroyable: true,
        editable: true,
        hasReport: true,
        houseCall: marketplace.additionalInfo.mobileBusiness,
        olbEnabled: true
    };
};
