import { Box, Theme, createStyles, makeStyles } from '@material-ui/core';
import { RootState } from 'store';
import useNonInitialEffect from '@versiondos/hooks';
import { fetchTwoWaySmsCountThunk, searchTwoWaySmsThunk } from 'actions/twoWaySms/twoWaySmsActions';
import { SmsChat } from 'model/TwoWaySms';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MessageFilterTypes } from '../Model';
import SearchChatsResultItem from './SearchChatsResultItem';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { wbp, reduceResolution } from 'Theme';
import { TwoWaySmsStatus } from 'reducers/twowaysms/TwoWaySmsState';
import Loader from '../ui/Loader';

interface SearchChatsResultProps {
    chatSearchText: string;
    selectedFilter: string;
    clientSelected?: number;
    onSelectClient?: (selectedClientId: number | undefined) => void;
    onClearSelectedClient?: () => void;
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chatsContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column'
        },
        chatContainer: {
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(25)}px`,
                marginBottom: `${reduceResolution(10)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '25px',
                marginBottom: '10px'
            },

            '&:hover': {
                backgroundColor: '#F8F5F1'
            }
        },
        chatScroll: {
            width: '100%',
            height: '100%',
            overflowY: 'scroll',
            overflowX: 'hidden',
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(15)}px ${reduceResolution(15)}px ${reduceResolution(
                    15
                )}px ${reduceResolution(15)}px`,
                maxHeight: reduceResolution(600),
                minHeight: `${reduceResolution(590)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '15px 15px 15px 15px',
                maxHeight: 600,
                minHeight: '590px'
            }
        }
    })
);

const pageSize = 30;

const SearchChatsResult: FunctionComponent<SearchChatsResultProps> = props => {
    const chatScrollRef = useRef<OverlayScrollbarsComponent>(null);

    const classes = useStyles();
    const dispatch = useDispatch();

    const chats = useSelector<RootState, Array<SmsChat>>(state => state.twoWaySms.chats);

    const twoWaySmsStatus = useSelector<RootState, TwoWaySmsStatus>(state => state.twoWaySms.status);
    const [page, setPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);

    const fetchChats = (page: number, pageSize: number) => {
        setLoading(true);
        if (props.chatSearchText.length === 0) {
            dispatch(fetchTwoWaySmsCountThunk());
            dispatch(searchTwoWaySmsThunk('', page, pageSize, 'all', 'newest'));
        } else if (props.chatSearchText.length >= 3) {
            dispatch(searchTwoWaySmsThunk(props.chatSearchText, page, pageSize, 'all', 'newest'));
        }
    };

    useEffect(() => {
        fetchChats(1, pageSize);
    }, []);

    useNonInitialEffect(() => {
        setLoading(true);
        switch (props.selectedFilter) {
            case MessageFilterTypes.NEWEST:
                dispatch(searchTwoWaySmsThunk(props.chatSearchText, page, pageSize, 'all', MessageFilterTypes.NEWEST));
                break;
            case MessageFilterTypes.OLDEST:
                dispatch(searchTwoWaySmsThunk(props.chatSearchText, page, pageSize, 'all', MessageFilterTypes.OLDEST));
                break;
            case MessageFilterTypes.UNREAD:
                dispatch(
                    searchTwoWaySmsThunk(props.chatSearchText, page, pageSize, 'unread', MessageFilterTypes.UNREAD)
                );
                break;
            default:
                break;
        }
        props.onClearSelectedClient && props.onClearSelectedClient();
    }, [props.selectedFilter, props.chatSearchText]);

    useNonInitialEffect(() => {
        if (twoWaySmsStatus === TwoWaySmsStatus.SearchSuccess) {
            setLoading(false);
        }
        if (twoWaySmsStatus === TwoWaySmsStatus.FetchSuccess) {
            setLoading(false);
        }
    }, [twoWaySmsStatus]);

    const handleChatScroll = () => {
        if (chatScrollRef.current) {
            const vp = chatScrollRef.current.osInstance()?.getElements().viewport;
            if (vp) {
                const scrollHeight = vp.scrollHeight;
                const height = vp.clientHeight;
                const maxScrollTop = scrollHeight - height;
                if (vp.scrollTop === maxScrollTop) {
                    const newN = pageSize + 5;
                    setPage(newN);
                }
            }
        }
        return null;
    };

    return (
        <OverlayScrollbarsComponent
            className={classes.chatScroll}
            options={{
                callbacks: {
                    onScrollStop: handleChatScroll
                }
            }}
            ref={chatScrollRef}
        >
            {chats && (
                <Box id="chatsContainer" className={classes.chatsContainer}>
                    {loading && <Loader />}
                    {!loading &&
                        chats.map(chat => {
                            return (
                                <Box key={chat.message.uuid + '-' + chat.client.id + '-' + '-root'}>
                                    <SearchChatsResultItem
                                        chat={chat}
                                        active={chat.client.id === props.clientSelected}
                                        onClick={() => {
                                            props.onSelectClient && props.onSelectClient(chat.client.id);
                                        }}
                                    />
                                </Box>
                            );
                        })}
                </Box>
            )}
        </OverlayScrollbarsComponent>
    );
};

export default SearchChatsResult;
