import { alertErrorHandler } from '@spike/notifications-action';
import ApiClient, { createTokenConfig, createMultipartTokenConfig, isCancelled } from 'api/ApiClient';
import { AxiosResponse } from 'axios';
import { SmsChat, SmsMessage } from 'model/TwoWaySms';
import moment from 'moment';
import { showError, showSuccess } from '@spike/notifications-action';
import { NotificationsAction } from '@spike/notifications-action';
import { TwoWaySmsState } from 'reducers/twowaysms/TwoWaySmsState';
import { ThunkAction } from 'redux-thunk';
import store, { RootState } from 'store';
import { serialize } from 'object-to-formdata';
import {
    TwoWaySmsAction,
    TWOWAYSMS_CLEAR_ACTION_TYPE,
    TWOWAYSMS_ERROR_ACTION_TYPE,
    TWOWAYSMS_FETCH_CONVERSATIONS_START_ACTION_TYPE,
    TWOWAYSMS_FETCH_CONVERSATIONS_SUCCESS_ACTION_TYPE,
    TWOWAYSMS_FETCH_START_ACTION_TYPE,
    TWOWAYSMS_FETCH_SUCCESS_ACTION_TYPE,
    TWOWAYSMS_FILTER_SUCCESS_ACTION_TYPE,
    TWOWAYSMS_GET_COUNT_START_ACTION_TYPE,
    TWOWAYSMS_GET_COUNT_SUCCESS_ACTION_TYPE,
    TWOWAYSMS_SAVE_START_ACTION_TYPE,
    TWOWAYSMS_SAVE_SUCCESS_ACTION_TYPE,
    TWOWAYSMS_SEARCH_SUCCESS_ACTION_TYPE,
    TWOWAYSMS_SET_CONVERSATIONS_SUCCESS_ACTION_TYPE,
    TWOWAYSMS_SET_CONVERSATION_SUCCESS_ACTION_TYPE,
    TWOWAYSMS_SET_COUNT_SUCCESS_ACTION_TYPE,
    TWOWAYSMS_SET_STORE_START_ACTION_TYPE
} from './twoWaySmsActionsTypes';
import { convertToSmsChat, convertToSmsMessage, convertToSmsMessageSaveDto } from './twoWaySmsConverter';
import {
    SmsFetchMessagesDto,
    SmsGetMessageCountDto,
    SmsMessageDto,
    SmsSearchChatResponseDto,
    SmsSearchMessagesDto
} from './twoWaySmsDtos';

export const clearTwoWaySmsMessagesThunk = (): ThunkAction<void, TwoWaySmsAction, null, TwoWaySmsAction> => {
    return async dispatch => {
        dispatch(clear());
    };
};

export const fetchTwoWaySmsCountThunk = (): ThunkAction<
    void,
    TwoWaySmsState,
    null,
    TwoWaySmsAction | NotificationsAction
> => {
    return async dispatch => {
        const marketplaceId = store.getState().login.auth.marketplaceId;
        dispatch(getCountStart());
        const url = `sms_messages_count?marketplace_id=${marketplaceId}`;

        try {
            const response: AxiosResponse<SmsGetMessageCountDto> = await ApiClient.get(
                url,
                createTokenConfig(store.getState().login.auth.token!)
            );
            dispatch(getCountSuccess(response.data));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error fetching SMS count data.'));
            }
        }
    };
};

export const fetchTwoWaySmsMessagesThunk = (
    clientId: number
): ThunkAction<void, TwoWaySmsState, null, TwoWaySmsAction | NotificationsAction> => {
    return async dispatch => {
        dispatch(fetchStart());
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `/sms_message/customer/${clientId}?marketplace_id=${marketplaceId}`;

        try {
            const response: AxiosResponse<SmsFetchMessagesDto> = await ApiClient.get(
                url,
                createTokenConfig(store.getState().login.auth.token!)
            );
            dispatch(fetchSuccess(response.data));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error fetching SMS data.' + apiError));
            }
        }
    };
};

export const saveMessageThunk = (message: SmsMessage): ThunkAction<void, any, null, any> => {
    return async dispatch => {
        dispatch(saveStart());
        const marketplaceId = store.getState().login.auth.marketplaceId;

        const smsMessageUrl = 'sms_messages';
        const apiClientMethod = message.id ? ApiClient.patch : ApiClient.post;
        const saveUrl = message.id ? `${smsMessageUrl}/${message.id}` : `${smsMessageUrl}`;

        const smsMessageRequest = {
            marketplace_id: marketplaceId,
            sms_message: convertToSmsMessageSaveDto(message)
        };

        let formData = new FormData();

        const options = {
            indices: false,
            nullsAsUndefineds: false,
            booleansAsIntegers: false,
            allowEmptyArrays: true
        };

        formData = serialize(smsMessageRequest, options, formData);

        try {
            const response: AxiosResponse<SmsMessageDto> = await apiClientMethod(
                saveUrl,
                formData,
                createMultipartTokenConfig(store.getState().login.auth.token!)
            );
            dispatch(saveSuccess(response.data));
            if (!message.id) {
                dispatch(showSuccess('Saved message successfully'));
            }
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                alertErrorHandler(apiError, dispatch, 'Error saving message.');
            }
        }
    };
};

export const searchTwoWaySmsThunk = (
    searchText?: string,
    page?: number,
    size?: number,
    filter?: string,
    sort?: string
): ThunkAction<void, TwoWaySmsState, null, TwoWaySmsAction | NotificationsAction> => {
    return async dispatch => {
        dispatch(fetchStart());

        const marketplaceId = store.getState().login.auth.marketplaceId;

        const searchTextVal = searchText ?? '';
        const sizeVal = !size || isNaN(size) ? '' : size;
        const filterVal = filter ?? 'all';
        const sortVal = sort ?? 'newest';
        const url = `sms_message/search_conversations?marketplace_id=${marketplaceId}&query=${searchTextVal}&size=${sizeVal}&filter=${filterVal}&sort=${sortVal}`;

        try {
            const response: AxiosResponse<SmsSearchChatResponseDto> = await ApiClient.get(
                url,
                createTokenConfig(store.getState().login.auth.token!)
            );
            dispatch(searchSuccess(response.data));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                alertErrorHandler(apiError, dispatch, 'Error searching TwoWaySms.');
            }
        }
    };
};

export const setTwoWaySmsThunk = (
    newMessage: SmsMessageDto,
    count?: number
): ThunkAction<void, TwoWaySmsState, null, TwoWaySmsAction | NotificationsAction> => {
    return async dispatch => {
        dispatch(setStoreStart());
        const actualConversation = store.getState().twoWaySms.conversation ?? [];
        const filteredConversations = store.getState().twoWaySms.chats ?? [];
        try {
            if (actualConversation && actualConversation[0]) {
                if (actualConversation[0].clientId === newMessage.customer_id) {
                    dispatch(setConversationSuccess(newMessage, actualConversation));
                }
            }
            dispatch(setConversationsSuccess(newMessage, filteredConversations));

            if (count) {
                dispatch(setCountSuccess(count));
            }
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error setting TwoWaySms data.'));
            }
        }
    };
};

const clear = (): TwoWaySmsAction => {
    return {
        type: TWOWAYSMS_CLEAR_ACTION_TYPE
    };
};

const fetchStart = (): TwoWaySmsAction => {
    return {
        type: TWOWAYSMS_FETCH_START_ACTION_TYPE
    };
};

export const setConversationSuccess = (data: SmsMessageDto, actualConversation: Array<SmsMessage>): TwoWaySmsAction => {
    const newMessage = convertToSmsMessage(data);

    const exist = actualConversation.some(message => message.id === newMessage.id);
    const newConversation = exist ? [...actualConversation] : [...actualConversation, newMessage];

    return {
        type: TWOWAYSMS_SET_CONVERSATION_SUCCESS_ACTION_TYPE,
        payload: {
            conversation: newConversation.sort(function (a, b) {
                return moment(a.sentAt) > moment(b.sentAt) ? 1 : moment(b.sentAt) > moment(a.sentAt) ? -1 : 0;
            })
        }
    };
};

export const fetchSuccess = (data: SmsFetchMessagesDto): TwoWaySmsAction => {
    const conversationFrom = data.messages_from?.map(message => convertToSmsMessage(message)) ?? [];
    const conversationTo = data.messages_to?.map(message => convertToSmsMessage(message)) ?? [];

    return {
        type: TWOWAYSMS_FETCH_SUCCESS_ACTION_TYPE,
        payload: {
            conversation: [...conversationFrom, ...conversationTo].sort(function (a, b) {
                return moment(a.sentAt) > moment(b.sentAt) ? 1 : moment(b.sentAt) > moment(a.sentAt) ? -1 : 0;
            })
        }
    };
};

const setStoreStart = (): TwoWaySmsAction => {
    return {
        type: TWOWAYSMS_SET_STORE_START_ACTION_TYPE
    };
};

export const setConversationsSuccess = (data: SmsMessageDto, filteredChats: Array<SmsChat>): TwoWaySmsAction => {
    const newMessage = convertToSmsMessage(data);
    const updatedChat = filteredChats.find(chat => chat.client.id === newMessage.clientId);
    if (updatedChat) {
        updatedChat.message = newMessage;
        updatedChat.unread = updatedChat!.unread + 1;
    }
    const chatIndex = filteredChats.findIndex(chat => chat.client.id === newMessage.clientId);
    if (chatIndex && updatedChat) {
        filteredChats[chatIndex] = updatedChat;
    }

    return {
        type: TWOWAYSMS_SET_CONVERSATIONS_SUCCESS_ACTION_TYPE,
        payload: {
            chats: [...filteredChats]
        }
    };
};

export const searchSuccess = (data: SmsSearchChatResponseDto): TwoWaySmsAction => {
    const chats = data.sms_conversations.map(conversation => convertToSmsChat(conversation)) ?? [];

    return {
        type: TWOWAYSMS_SEARCH_SUCCESS_ACTION_TYPE,
        payload: {
            chats: [...chats]
        }
    };
};

const getCountStart = (): TwoWaySmsAction => {
    return {
        type: TWOWAYSMS_GET_COUNT_START_ACTION_TYPE
    };
};

export const getCountSuccess = (data: SmsGetMessageCountDto): TwoWaySmsAction => {
    const unreadMapped = data.unread?.map(message => convertToSmsMessage(message));
    return {
        type: TWOWAYSMS_GET_COUNT_SUCCESS_ACTION_TYPE,
        payload: {
            messageQuantity: unreadMapped!.length,
            unreadMessages: unreadMapped ?? []
        }
    };
};

export const setCountSuccess = (count: number): TwoWaySmsAction => {
    return {
        type: TWOWAYSMS_SET_COUNT_SUCCESS_ACTION_TYPE,
        payload: {
            messageQuantity: count
        }
    };
};

const saveStart = (): TwoWaySmsAction => {
    return {
        type: TWOWAYSMS_SAVE_START_ACTION_TYPE
    };
};

const saveSuccess = (smsMessageDto: SmsMessageDto): TwoWaySmsAction => {
    return {
        type: TWOWAYSMS_SAVE_SUCCESS_ACTION_TYPE,
        payload: {
            message: convertToSmsMessage(smsMessageDto)
        }
    };
};

const error = (): TwoWaySmsAction => {
    return {
        type: TWOWAYSMS_ERROR_ACTION_TYPE
    };
};
