import React, { createContext, useContext, useEffect, useReducer, FunctionComponent, ReactNode } from 'react';
import {
    FixedPriceOption,
    fixedPriceOption,
    getPricingOptions,
    getPricingVariablesOption,
    initializePricesObject,
    setPricingVariablesOption,
    VariablePriceOption
} from '../model';
import Service, { ServicePricing } from 'model/Service';
import pricingReducer, { initialState, PricingState } from './PricingReducer';
import {
    setSelectedPricingOption,
    setPetTypes,
    setPricingObject,
    setSelectedPetType,
    setPricingOptions,
    setPricing,
    updateSelectedPricingOption,
    updatePricing,
    setPricingVariables,
    setPricingTaxes,
    createServicePricingObjectStart,
    createServicePricingObjectSuccess,
    createServicePricingObjectError,
    resetPricingState,
    showPricingErrors
} from './PricingActions';
import { useMarketplace } from 'hooks';
import { Option, PetType, PetTypeIds } from '@spike/model';
import { MarketplaceTax } from '@spike/marketplace-model';
import { cloneDeep, isEmpty, isEqual } from 'lodash';
import { validateServicePricingV2 } from 'components/Service/Validations';
import useNonInitialEffect from '@versiondos/hooks';

interface PricingContextProps {
    service: Service;
    state: PricingState;
    setSelectedPetType: (petType: PetType) => void;
    updateSelectedPricingOption: (pricingOption: Option<string>) => void;
    updatePricing: (price: Array<VariablePriceOption | FixedPriceOption>) => void;
    updateTaxes: (taxes: Array<MarketplaceTax>) => void;
    createServicePricingObject: () => void;
    updatePetTypes: (petTypes: Array<PetType>) => void;
    showPricingErrors: (showErrors: boolean) => void;
    reset: () => void;
}

const MODULE_NAME = 'PricingContext';

const PricingContext = createContext<PricingContextProps | undefined>(undefined);

export const PricingProvider: FunctionComponent<{ service: Service; children: ReactNode }> = ({
    service,
    children
}) => {
    const { petTypes: marketplacePetTypes, deposit } = useMarketplace();
    const [state, dispatch] = useReducer(pricingReducer, initialState);

    const getInitialState = () => {
        if (service.petTypes && service.petTypes.length > 0) {
            dispatch(setSelectedPetType(service.petTypes[0]));
            dispatch(setPetTypes(service.petTypes));
            const initialPricingOptions = getPricingOptions(service.petTypes);
            if (initialPricingOptions && initialPricingOptions.options) {
                dispatch(setPricingOptions(initialPricingOptions.options));
            }
            const initialPricingOption = getPricingVariablesOption(service.pricing.variablePrice?.variables);
            const shouldUseFixedPrice = !service.petTypes[0] || service.petTypes[0].id === PetTypeIds.EXOTICS;
            dispatch(setSelectedPricingOption(shouldUseFixedPrice ? fixedPriceOption : initialPricingOption));
            dispatch(setPricingVariables(initialPricingOption, service.petTypes));
            const initialPricingObject = initializePricesObject(marketplacePetTypes, service.petTypes, service.pricing);
            dispatch(setPricingObject(initialPricingObject));
            if (initialPricingOption && initialPricingObject) {
                const initialPricing = cloneDeep(initialPricingObject[initialPricingOption.id]);
                dispatch(setPricing(initialPricing));
            }
            dispatch(setPricingTaxes(service.pricing.taxes));
        }
    };

    useEffect(() => {
        getInitialState();
    }, []);

    useNonInitialEffect(() => {
        if (state.petTypes.length > 0) {
            dispatch(setSelectedPetType(state.petTypes[0]));
            const initialPricingOptions = getPricingOptions(state.petTypes);
            if (initialPricingOptions) {
                dispatch(setPricingOptions(initialPricingOptions.options));
            }
            const initialVariables = setPricingVariablesOption(state.selectedPricingOption.id, state.petTypes);
            const initialPricingOption = getPricingVariablesOption(initialVariables);
            const shouldUseFixedPrice = !state.petTypes[0] || state.petTypes[0].id === PetTypeIds.EXOTICS;
            const pricingOption = shouldUseFixedPrice ? fixedPriceOption : initialPricingOption;
            dispatch(setSelectedPricingOption(pricingOption));
            dispatch(setPricingVariables(pricingOption, state.petTypes));
            const initialPricingObject = initializePricesObject(marketplacePetTypes, state.petTypes, service.pricing);
            dispatch(setPricingObject(initialPricingObject));
            if (initialPricingObject && pricingOption) {
                const initialPricing = cloneDeep(initialPricingObject[pricingOption.id]);
                if (initialPricing) {
                    dispatch(setPricing(initialPricing));
                }
            }
            dispatch(showPricingErrors(false));
        }
    }, [state.petTypes]);

    const selectPetTypeHandler = (petType: PetType) => {
        dispatch(setSelectedPetType(petType));
    };

    const updateSelectedPricingOptionHandler = (pricingOption: Option<string>) => {
        dispatch(updateSelectedPricingOption(pricingOption));
        dispatch(setPricingVariables(pricingOption, service.petTypes));
    };

    const updatePricingHandler = (price: Array<VariablePriceOption | FixedPriceOption>) => {
        dispatch(updatePricing(price));
    };

    const updateTaxesHandler = (taxes: Array<MarketplaceTax>) => {
        dispatch(setPricingTaxes(taxes));
    };

    const updatePetTypesHandler = (petTypes: Array<PetType>) => {
        dispatch(setPetTypes(petTypes));
    };

    const showPricingErrorsHandler = (showErrors: boolean) => {
        dispatch(showPricingErrors(showErrors));
    };

    const createServicePricingObjectHandler = () => {
        dispatch(createServicePricingObjectStart());
        const servicePricingObject: ServicePricing = {
            fixedPrice: isEqual(state.selectedPricingOption.id, fixedPriceOption.id)
                ? (state.pricing as Array<FixedPriceOption>)
                : [],
            variablePrice: !isEqual(state.selectedPricingOption.id, fixedPriceOption.id)
                ? {
                      prices: state.pricing as Array<VariablePriceOption>,
                      variables: state.pricingVariables
                  }
                : undefined,
            taxable: !isEmpty(state.taxes),
            taxes: isEmpty(state.taxes) ? [] : state.taxes
        };
        const errors = validateServicePricingV2(servicePricingObject, deposit);
        dispatch(createServicePricingObjectSuccess(servicePricingObject));
        if (!isEmpty(errors)) {
            dispatch(createServicePricingObjectError(errors));
        }
    };

    const resetHandler = () => {
        dispatch(resetPricingState());
    };

    useEffect(() => {
        createServicePricingObjectHandler();
    }, [state.pricing]);

    return (
        <PricingContext.Provider
            value={{
                service,
                state,
                setSelectedPetType: selectPetTypeHandler,
                updateSelectedPricingOption: updateSelectedPricingOptionHandler,
                updatePricing: updatePricingHandler,
                updateTaxes: updateTaxesHandler,
                createServicePricingObject: createServicePricingObjectHandler,
                updatePetTypes: updatePetTypesHandler,
                showPricingErrors: showPricingErrorsHandler,
                reset: resetHandler
            }}
        >
            {children}
        </PricingContext.Provider>
    );
};

export const usePricingContext = (): PricingContextProps => {
    const context = useContext(PricingContext);
    if (!context) {
        throw new Error('usePricingContext must be used within a PricingProvider');
    }
    return context;
};
