import { faUsers, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Box, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { wbp, reduceResolution } from 'Theme';
import { Button } from 'components/UI';
import IconCircle from 'components/UI/IconCircle';
import TitlePrimary from 'components/UI/Title/TitlePrimary';
import { FunctionComponent } from 'react';

interface SearchClientEmptyProps {
    onClientAdd: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        noClientContainer: {
            flex: 1,
            fontSize: 15,
            lineHeight: 1.4,
            [theme.breakpoints.down(wbp)]: {
                paddding: `${reduceResolution(40)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '40px'
            },
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        noClientIcon: {
            margin: '0px auto 15px'
        },
        noClientTitle: {
            fontWeight: 600,
            fontSize: '26px !important'
        },
        noClientParagraph: {
            fontSize: 15,
            lineHeight: 1.4,
            marginBottom: 20
        },
        newClientButton: {
            width: 'auto',
            fontWeight: 500,
            paddingLeft: '35px',
            paddingRight: '35px',
            margin: '0px auto',
            display: 'inline-block'
        }
    })
);
const SearchClientEmpty: FunctionComponent<SearchClientEmptyProps> = props => {
    const classes = useStyles();

    return (
        <Box>
            <IconCircle icon={faUsers} className={classes.noClientIcon} />
            <TitlePrimary className={classes.noClientTitle}>No Clients found under that search</TitlePrimary>
            <Typography className={classes.noClientParagraph}>You can always add a new client.</Typography>
            <Box className={classes.newClientButton}>
                <Button
                    color="orange"
                    size="medium"
                    startIcon={faPlus}
                    label="Add client"
                    onClick={() => props.onClientAdd && props.onClientAdd()}
                />
            </Box>
        </Box>
    );
};

export default SearchClientEmpty;
