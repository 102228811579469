import React, { FunctionComponent, useState, useEffect } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, ClickAwayListener, useMediaQuery } from '@material-ui/core';
import { faCalendarDays } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';
import clsx from 'clsx';
import { Button } from 'components/UI';
import { SelectableOption } from 'model';
import { Period } from '@spike/model';
import CalendarDropdown from './CalendarDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { getLimitsThunk } from '@spike/bookings-action';
import { RootState } from 'store';
import { AppointmentDuration } from '@spike/appointment-model';
import { useApiClientWrapper } from 'hooks';
import useNonInitialEffect from '@versiondos/hooks';

interface CalendarFilterProps {
    optionSelected?: string;
    period: Period;
    fromClient?: boolean;
    disableFuture?: boolean;
    className?: string;
    dropdownAlignment?: 'left' | 'right';
    hideAllTime?: boolean;
    id?: string;
    showLabelOnMobile?: boolean;
    hideUpcoming?: boolean;
    onChange: (period: Period, allTime: boolean, opSelected?: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            position: 'relative'
        },
        button: {
            [theme.breakpoints.down('md')]: {
                'height': 39,
                'minWidth': 'unset',
                'borderColor': '#000',
                'borderRadius': 30,

                'width': (props: CalendarFilterProps) => (props.showLabelOnMobile ? 'auto' : 39),
                'padding': (props: CalendarFilterProps) => (props.showLabelOnMobile ? '0px 16px' : 0),

                '& span': {
                    lineHeight: 1,
                    margin: '0px !important'
                },
                '& svg': {
                    width: 13,
                    height: 'auto',

                    marginRight: (props: CalendarFilterProps) => (props.showLabelOnMobile ? 6 : 0)
                },
                '& .MuiButton-label': {
                    fontSize: '10.5px'
                }
            }
        },
        dropdownContainer: {
            position: 'relative'
        },
        dropdown: {
            position: 'absolute',
            zIndex: 1,
            top: '31px',
            [theme.breakpoints.down(768)]: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 99999,
                position: 'fixed'
            }
        }
    })
);

const today = { id: '1', name: 'Today' };
const tomorrow = { id: '2', name: 'Tomorrow' };
const next7Days = { id: '3', name: 'Next 7 days' };
const thisMonth = { id: '7', name: 'This month' };
const last30Days = { id: '4', name: 'Last 30 days' };
const lastMonth = { id: '5', name: 'Last month' };
const allTime = { id: '6', name: 'All time' };
const upcoming = { id: '8', name: 'Upcoming' };

const futureIds = [tomorrow.id, next7Days.id];

export const CalendarFilter: FunctionComponent<CalendarFilterProps> = props => {
    const classes = useStyles(props);
    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    const appointmentLimits = useSelector<RootState, Period | undefined>(state => state.appointments.limits);

    const [activeCalendar, setActiveCalendar] = useState(false);
    const [optionSelected, setOptionSelected] = useState(props.optionSelected);
    const [selectionRange, setSelectionRange] = useState<Period>(props.period);
    const [selectionRangeFormat, setSelectionRangeFormat] = useState({
        from: '',
        to: ''
    });

    const isSelectedOption = (value: string): boolean => {
        if (!props.optionSelected) {
            if (value === 'Today') {
                return true;
            }
            return false;
        }
        return value === props.optionSelected;
    };

    const [calendarOptions, setCalendarOptions] = useState<Array<SelectableOption<string>>>(
        [
            { element: today, selected: isSelectedOption('Today') },
            { element: tomorrow, selected: isSelectedOption('Tomorrow') },
            { element: next7Days, selected: isSelectedOption('Next 7 days') },
            { element: thisMonth, selected: isSelectedOption('This month') },
            { element: last30Days, selected: isSelectedOption('Last 30 days') },
            { element: lastMonth, selected: isSelectedOption('Last month') },
            { element: allTime, selected: isSelectedOption('All time') },
            { element: upcoming, selected: isSelectedOption('Upcoming') }
        ].filter(option => {
            return (
                (!props.disableFuture || !futureIds.includes(option.element.id)) &&
                (!props.hideAllTime || option.element.id !== allTime.id) &&
                !(props.hideUpcoming && option.element.id === upcoming.id)
            );
        })
    );

    useNonInitialEffect(() => {
        setSelectionRangeFormat({
            from: moment(selectionRange.from).format('ll'),
            to: moment(selectionRange.to).format('ll')
        });
    }, [selectionRange]);

    useEffect(() => {
        if (props.fromClient && appointmentLimits) {
            dispatch(getLimitsThunk(apiClientWrapper));

            const appointmentLimitsAux = {
                from: moment(),
                to: appointmentLimits.to
            };
            setActiveCalendar(false);
            handleChangeDate(appointmentLimitsAux, false);
            const datePeriodDefault = props.period ? props.period : appointmentLimitsAux;
            if (props.optionSelected) {
                handleSelectOption(props.optionSelected, datePeriodDefault);
            } else {
                handleSelectOption('Upcoming', datePeriodDefault);
            }
        }
    }, [props.fromClient]);

    useEffect(() => {
        if (!activeCalendar) {
            dispatch(getLimitsThunk(apiClientWrapper));
        }
    }, [activeCalendar]);

    const dateButtonLabel = () => {
        if (isMobile && !props.showLabelOnMobile) {
            return '';
        } else if (optionSelected) {
            return optionSelected;
        } else if (selectionRangeFormat.from === selectionRangeFormat.to) {
            return selectionRangeFormat.from;
        } else {
            return `${selectionRangeFormat.from} - ${selectionRangeFormat.to}`;
        }
    };

    const handleActivateCalendar = (): void => {
        setActiveCalendar(!activeCalendar);
    };

    const handleChangeDate = (dateSelected: Period, isVisible: boolean): void => {
        setSelectionRange(dateSelected);
        setActiveCalendar(isVisible);
    };

    const handleApplyChangesDate = (dateSelected: Period, isVisible: boolean): void => {
        setSelectionRange(dateSelected);
        setActiveCalendar(isVisible);
        props.onChange(dateSelected, false);
    };

    const handleSelectRange = (dateSelected: Period): void => {
        setSelectionRange(dateSelected);
    };

    const handleSelectOption = (opSelected: string, dateSelected: AppointmentDuration | undefined): void => {
        const calendarOptionsAux = calendarOptions;

        calendarOptionsAux.forEach(option => {
            if (option.element.name === opSelected) {
                option.selected = !option.selected;
            } else {
                option.selected = false;
            }
        });

        if (dateSelected) {
            props.onChange(dateSelected, allTime.name === opSelected, opSelected);
        }

        setCalendarOptions(calendarOptionsAux);
        setOptionSelected(opSelected);
    };

    const calendarDropdown = (
        <ClickAwayListener
            onClickAway={() => {
                setActiveCalendar(false);
            }}
        >
            <Box className={classes.dropdownContainer}>
                <CalendarDropdown
                    id={`${props.id || ''}_calendar_dropdown`}
                    appointmentLimits={appointmentLimits}
                    selectedRange={selectionRange}
                    calendarOptions={calendarOptions}
                    optionSelected={optionSelected}
                    onChange={handleChangeDate}
                    onApplyChanges={handleApplyChangesDate}
                    onSelectRange={handleSelectRange}
                    onSelectOption={handleSelectOption}
                    className={classes.dropdown}
                    dropdownAlignment={props.dropdownAlignment}
                />
            </Box>
        </ClickAwayListener>
    );

    const button = (
        <Button
            label={
                isMobile
                    ? dateButtonLabel().length > 10
                        ? dateButtonLabel().substring(0, 6) + '...'
                        : dateButtonLabel()
                    : dateButtonLabel()
            }
            startIcon={faCalendarDays}
            onClick={handleActivateCalendar}
            variant="secondary"
            color="green"
            id={`${props.id || ''}_calendar_button`}
            size={isMobile ? 'medium' : 'large'}
            className={classes.button}
        />
    );

    return (
        <Box className={clsx(classes.container, props.className)}>
            {props.dropdownAlignment === 'left' && activeCalendar && calendarDropdown}
            {button}
            {props.dropdownAlignment !== 'left' && activeCalendar && calendarDropdown}
        </Box>
    );
};

export default CalendarFilter;
