import { MarketplaceLogos } from '@spike/marketplace-model';
import { FunctionComponent } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import olbHalfscreen from 'rocket/assets/images/olb-halfscreen.jpg';
import olbDefault from 'rocket/assets/images/olb-default.jpg';
import clsx from 'clsx';

export interface Props {
    logos: MarketplaceLogos;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
    leftColumn: {
        height: '260px',
        width: '240px'
    },
    rightColumn: {
        height: '237px',
        paddingTop: '23px',
        width: '180px'
    },
    container: {
        backgroundColor: '#fafafa',
        boxShadow: '0px 10.32px 25.8px 0px #0000001A',
        position: 'absolute',
        width: '420px',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
        [theme.breakpoints.up('md')]: {
            bottom: '0',
            right: '-115%'
        },
        [theme.breakpoints.up('lg')]: {
            bottom: '0',
            right: '-30%'
        },
        [theme.breakpoints.only('xl')]: {
            bottom: '0',
            right: '0'
        }
    }
}));

export const Preview: FunctionComponent<Props> = props => {
    const classes = useStyles();

    return (
        <Grid container className={clsx(classes.container, props.className)}>
            <Grid item className={classes.leftColumn}>
                <img src={olbHalfscreen} alt="Online booking preview" width={240} height={260} />
            </Grid>
            <Grid item className={classes.rightColumn}>
                {props.logos.promoImageUrl ? (
                    <img src={props.logos.promoImageUrl} alt="Woman with puppy" width={180} height={237} />
                ) : (
                    <img src={olbDefault} alt="Woman with puppy" width={180} height={237} />
                )}
            </Grid>
        </Grid>
    );
};

export default Preview;
