import { faSpinner, faExclamationTriangle, faCheck } from '@fortawesome/pro-regular-svg-icons';

export enum ApplicationStatus {
    Review,
    Processing,
    Setup,
    NonUs
}

export const statusConfig = {
    [ApplicationStatus.Review]: {
        title: 'Review Your Application',
        description:
            "Take a moment to review the details you've provided in your application. Make sure all information is accurate before final submission.",
        buttonLabel: 'Review Now',
        icon: faExclamationTriangle,
        showButton: true
    },
    [ApplicationStatus.Processing]: {
        title: 'Application Processing',
        description:
            "Your CuddlesPay application has been submitted successfully. Our team is currently reviewing it. Once approved, you'll gain access to the CuddlesPay dashboard.",
        buttonLabel: 'View Status',
        icon: faSpinner,
        showButton: false
    },
    [ApplicationStatus.Setup]: {
        title: 'CuddlesPay Setup Completed',
        description:
            'Congratulations! Your payment setup with Cuddles Pay is now active. If you need to update your information, simply click the button below',
        buttonLabel: 'Update Information',
        icon: faCheck,
        showButton: true
    },
    [ApplicationStatus.NonUs]: {
        title: 'CuddlesPay is not available in your country',
        description: "We're working on making CuddlesPay available internationally. Stay tuned for updates!",
        buttonLabel: 'Contact Support',
        icon: faExclamationTriangle,
        showButton: false
    }
};
