import { MarketplaceLogos, MarketplaceOnlineBooking } from '@spike/marketplace-model';
import { Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import useNonInitialEffect from '@versiondos/hooks';
import { faCheck } from '@fortawesome/pro-regular-svg-icons/faCheck';
import { faCopy } from '@fortawesome/pro-regular-svg-icons/faCopy';
import clsx from 'clsx';
import { FunctionComponent, useState } from 'react';
import Section from './Section';
import useMasterData from 'hooks/useMasterData';
import { Button, TextFieldWithButton } from 'components/UI';
import isEqual from 'lodash/isEqual';
import { MarketplaceStatus } from '@spike/marketplace-action';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import Preview from './Preview';
import UploadImage from './UploadImage';

export interface Props {
    onlineBooking: MarketplaceOnlineBooking;
    className?: string;
    logos: MarketplaceLogos;
    onSave?: (logos: MarketplaceLogos) => void;
    onChange?: (logos: MarketplaceLogos) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            paddingTop: '36px'
        },
        row: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.only('md')]: {
                marginTop: '4px',
                marginBottom: '4px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '10px',
                marginBottom: '10px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '13px',
                marginBottom: '13px'
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: '10px',
                marginBottom: '10px'
            }
        },
        rowInput: {
            [theme.breakpoints.down('sm')]: {
                '& input': {
                    height: '52px !important',
                    fontSize: '16px',
                    fontWeight: '400',
                    textOverflow: 'ellipsis !important',
                    paddingRight: '5px !important'
                },
                '& button': {
                    'height': '37px',
                    'width': '100px',
                    'paddingLeft': '16px',
                    'paddingRight': '15px',
                    '& .MuiButton-label': {
                        'fontSize': '14px',
                        'fontWeight': '600',
                        '& .MuiButton-startIcon': {
                            'marginRight': '7px',
                            '& svg': {
                                width: '14px',
                                height: '14px'
                            }
                        }
                    }
                },
                '& fieldset': {
                    borderColor: '#f00 !important'
                }
            }
        },
        subtitle: {
            width: '100%',
            fontSize: '16px',
            lineHeight: '25px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '140%',
                marginBottom: '0px'
            }
        },
        sectionContainer: {
            borderTop: 'none',
            paddingTop: 'unset',
            position: 'relative'
        },
        imageContainer: {
            paddingTop: '24px'
        },
        SettingsSquareLogo: {
            marginBottom: '16px',
            [theme.breakpoints.up('xs')]: {
                'width': '88px !important',
                'height': '88px !important',
                '& >div': {
                    width: 'inherit',
                    height: 'inherit'
                }
            }
        },
        SquareLogoDropdown: {
            left: '20%',
            top: '5%'
        },
        buttonContainer: {
            display: 'flex',
            width: '100%',
            marginTop: '16px'
        },
        buttonContainerFull: {
            [theme.breakpoints.down('sm')]: {
                'width': '100%',
                'left': 0,
                '& button': {
                    'width': '100%',
                    'height': '50px',
                    'borderRadius': '31px',
                    '& .MuiButton-label': {
                        fontSize: '16px'
                    }
                }
            }
        },
        squareContainer: {
            position: 'relative'
        }
    })
);

export const Profile: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const onlineBookingPath = `${process.env.REACT_APP_ONLINE_BOOKING_HOST_URL}${props.onlineBooking.slug}`;

    const status = useSelector<RootState, MarketplaceStatus>(state => state.marketplace.status);
    const [buttonMessage, setButtonMessage] = useState('Copy');
    const [buttonIcon, setButtonIcon] = useState(faCopy);
    const [urlCopied, setUrlCopied] = useState(false);
    const [saved, setSaved] = useState(false);
    const [logos, setLogos] = useState(props.logos);
    const [loading, setLoading] = useState(false);

    const masterData = useMasterData();

    useNonInitialEffect(() => {
        setButtonMessage('Copied');
        setButtonIcon(faCheck);
    }, [urlCopied]);

    useNonInitialEffect(() => {
        if (status === MarketplaceStatus.SaveSuccess) {
            setSaved(true);
            setLoading(false);
        }
    }, [status]);

    const copyToClipboard = () => {
        setUrlCopied(true);
        if ('clipboard' in navigator) {
            navigator.clipboard.writeText(onlineBookingPath);
        } else {
            document.execCommand('copy', true, onlineBookingPath);
        }
    };

    const handleChangeImageSquare = (imageFile: File | undefined, image: string | undefined) => {
        setLogos(prev => ({
            ...prev,
            promoImageUrl: image,
            promoFileToUpload: imageFile,
            promoImageRemove: imageFile ? false : true
        }));
        setSaved(isEqual(props.logos, imageFile));
    };

    const saveHandler = () => {
        props.onSave && props.onSave({ ...logos });
        props.onSave && setLoading(true);
    };

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Box className={classes.row}>
                <Typography className={classes.subtitle}>
                    Share your link today and connect with your clients to schedule their next appointment.
                </Typography>
            </Box>
            <Box className={clsx(classes.row, classes.rowInput)}>
                <TextFieldWithButton
                    value={onlineBookingPath}
                    label={buttonMessage}
                    startIcon={buttonIcon}
                    blackButton={true}
                    onClick={copyToClipboard}
                    classNameCustom="onlineBookingInput"
                />
            </Box>
            <Section
                title="Add a Photo to your Online Booking"
                description="You can upload a custom photo to your online booking."
                className={classes.sectionContainer}
            >
                <Box className={classes.imageContainer}>
                    <UploadImage
                        imageUrl={logos.promoImageUrl}
                        onChangeImage={handleChangeImageSquare}
                        maxSize={masterData.imagesMaxSizes.onlineBookingImage.maxSizeKb}
                        maxWidth={masterData.imagesMaxSizes.onlineBookingImage.maxWidthPx}
                        maxHeight={masterData.imagesMaxSizes.onlineBookingImage.maxHeightPx}
                        imageTypes={masterData.imagesMaxSizes.onlineBookingImage.imageTypes}
                        type="square"
                    />
                </Box>
                <Box className={clsx(classes.buttonContainer, classes.buttonContainerFull)}>
                    <Button label={saved ? 'Saved!' : 'Save'} onClick={saveHandler} loading={loading} />
                </Box>
                <Preview logos={logos} />
            </Section>
        </Box>
    );
};

export default Profile;
