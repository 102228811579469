import React, { FunctionComponent } from 'react';
import { Box, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faBook, faPhone } from '@fortawesome/pro-light-svg-icons';
import { HelpCenterPath } from '../../routes/Paths';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flex: 1,
            overflowY: 'auto',
            textAlign: 'center'
        },
        container: {
            maxWidth: 750,
            margin: '0 auto',
            display: 'flex',
            minHeight: '100%',
            padding: '32px 20px',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        title: {
            fontSize: 32,
            lineHeight: 1,
            color: '#BAA997',
            marginBottom: 24,
            fontFamily: 'YoungSerif',

            [theme.breakpoints.up('md')]: {
                fontSize: 46
            }
        },
        paragraph: {
            'fontSize': 15,
            'lineHeight': 1.5,

            '& + &': {
                marginTop: 24
            },

            '& b': {
                fontWeight: 600
            },

            '& a': {
                fontWeight: 600,
                color: '#EAB464',
                textDecoration: 'underline'
            }
        },
        paragraphSmall: {
            maxWidth: 475,
            margin: '0 auto'
        },
        cardsGrid: {
            gap: 24,
            marginTop: 48,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',

            [theme.breakpoints.up('md')]: {
                flexDirection: 'row'
            }
        },
        card: {
            display: 'flex',
            borderRadius: 20,
            textAlign: 'left',
            color: '#222222',
            padding: '24px 19px',
            position: 'relative',
            alignItems: 'center',
            textDecoration: 'none',
            border: '1px solid #D3D3D3',

            [theme.breakpoints.up('md')]: {
                width: 330
            }
        },
        cardContent: {
            gap: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start'
        },
        cardIcon: {
            fontSize: 32,
            marginBottom: 4
        },
        cardTitle: {
            fontSize: 16,
            lineHeight: 1,
            fontWeight: 600,
            color: '#000000',
            textAlign: 'left'
        },
        cardDescription: {
            'fontSize': 15,
            'lineHeight': 1.4,
            'color': '#000000',

            '& a': {
                color: '#EAB464',
                fontWeight: 600,
                textDecoration: 'underline'
            }
        },
        cardArrow: {
            fontSize: 22,
            marginLeft: 16
        }
    })
);

export const HelpSupport: FunctionComponent = () => {
    const classes = useStyles();

    return (
        <>
            <Box className={classes.root}>
                <Box className={classes.container}>
                    <Typography className={classes.title}>Help & Support</Typography>

                    <Typography className={classes.paragraph}>
                        Cuddles Support Team is available <br />
                        <b>Mon-Fri 8 AM - 8 PM EST / Sat-Sun 10 AM - 7 PM EST.</b>
                    </Typography>
                    <Typography className={classes.paragraph}>
                        If it&apos;s outside of business hours please email us at{' '}
                        <a href="mailto:help@cuddlesapp.com" target="_blank" rel="noreferrer">
                            help@cuddlesapp.com
                        </a>
                        . <br />
                        We&apos;ll get back to you when business hours resume.
                    </Typography>
                    <Typography className={clsx(classes.paragraph, classes.paragraphSmall)}>
                        If it is an emergency, please still email or text the team as we will monitor these channels in
                        the event of an incident that has significant impact to your ability to run your business.
                    </Typography>

                    <Box className={classes.cardsGrid}>
                        <a target="_blank" rel="noreferrer" href={HelpCenterPath} className={classes.card}>
                            <Box className={classes.cardContent}>
                                <FontAwesomeIcon icon={faBook} className={classes.cardIcon} />
                                <Typography className={classes.cardTitle}>Help Center</Typography>
                                <Typography className={classes.cardDescription}>
                                    Learn how to use Cuddles with our user-friendly guides.
                                </Typography>
                            </Box>

                            <FontAwesomeIcon icon={faAngleRight} className={classes.cardArrow} />
                        </a>

                        {/*      <Box className={classes.card}>
                            <div>
                                <div style={{ display: 'flex' }}>
                                    <FontAwesomeIcon
                                        icon={faComments}
                                        className={classes.icon}
                                    />
                                </div>
                                <Typography className={classes.firstHelpText}>
                                    Live Chat
                                </Typography>
                                <Typography className={classes.secondHelpText}>
                                    Chat with one of our specialists for
                                    personalized assistance.{' '}
                                </Typography>
                            </div>
                            <Typography className={classes.arrowRight}>
                                {'>'}
                            </Typography>
                        </Box>*/}

                        <Box className={classes.card}>
                            <Box className={classes.cardContent}>
                                <FontAwesomeIcon icon={faPhone} className={classes.cardIcon} />
                                <Typography className={classes.cardTitle}>Call Support</Typography>
                                <Typography className={classes.cardDescription}>
                                    Need to talk? Call us at{' '}
                                    <a href="sms:+17865898249">
                                        <span style={{ color: '#EAB464' }}>786-589-8249</span>
                                    </a>
                                    , and let&apos;s get it sorted out!
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default HelpSupport;
