import { faFileArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Button } from 'components/UI';
import { FunctionComponent } from 'react';

interface ExportButtonProps {
    exporting?: boolean;
    onClick?: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        csvButton: {
            [theme.breakpoints.down('md')]: {
                'height': 39,
                'minWidth': 'unset',
                'borderRadius': 30,
                'width': 39,
                'padding': 0,
                '& span': {
                    lineHeight: 1,
                    margin: '0px !important'
                },
                '& svg': {
                    width: 16,
                    height: 'auto',
                    marginRight: '0px !important'
                },
                '& .MuiButton-startIcon': {
                    marginLeft: '2px !important'
                }
            }
        }
    })
);

export const ExportButton: FunctionComponent<ExportButtonProps> = props => {
    const classes = useStyles();

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return (
        <Button
            startIcon={faFileArrowDown}
            label={isMobile ? '' : 'Export'}
            color="black"
            size={isMobile ? 'small' : 'large'}
            className={classes.csvButton}
            loading={props.exporting}
            onClick={props.onClick}
        />
    );
};

export default ExportButton;
