import { Box, Grid, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { PetMedicalHistory } from '@spike/medical-history-model';
import { FieldError } from '@spike/model';
import { showErrorThunk } from '@spike/notifications-action';
import { Note, Pet } from '@spike/pet-model';
import { PetsStatus, deletePetThunk, savePetThunk } from '@spike/pets-action';
import Vet from '@spike/vet-model';
import { getVetThunk, reset as resetVet } from '@spike/vets-action';
import useNonInitialEffect from '@versiondos/hooks';
import { reduceResolution, wbp } from 'Theme';
import BookingsComponentClient from 'components/Bookings/BookingsComponentClient';
import { Button, CannotBeDeleted, ConfirmDialog, FormSection } from 'components/UI';
import { useApiClientWrapper } from 'hooks';
import cloneDeep from 'lodash/cloneDeep';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { DeceasedPet } from './DeceasedPet';
import Header from './Header';
import MedicalHistory from './MedicalHistory';
import PetForm from './PetForm';
import PetNotes from './PetNotes';
import { validatePet } from './Validations';
import CreateBookingDrawer from 'components/UI/CreateBookingDrawer';

interface EditPetProps {
    pet: Pet;
    editMedicalHistory?: boolean;
    editPetNotes?: boolean;
    overfullWindow?: boolean;
    hideTabs?: boolean;
    hideHeadingComponent?: boolean;
    onSaved?: (pet: Pet, petNotesEdited: boolean) => void;
    onDeleted?: () => void;
    onBooked?: () => void;
    onSelect?: () => void;
    onClose?: () => void;
    onSelectDropdown?: (key: string) => void;
    onClikedActions?: () => void;
}

interface Book {
    petId: number;
    clientId: number;
    clientName: string;
}

interface CannotBeDeletedMessageProps {
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        body: {
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(59)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '59px'
            },
            [theme.breakpoints.down('md')]: {
                'overflowX': 'hidden',
                'backgroundColor': '#fff',
                'padding': '15px',
                '& #clients_div_pet_form': {
                    marginBottom: '15px'
                }
            }
        },
        footer: {
            display: 'flex',
            justifyContent: 'flex-end',
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(51)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '51px'
            },
            [theme.breakpoints.down('md')]: {
                '& button': {
                    height: '50px',
                    width: '100%',
                    borderRadius: '30px',
                    [theme.breakpoints.down('sm')]: {
                        'width': '100%',
                        'flexGrow': 1,
                        'height': 55,
                        '& span': {
                            fontSize: 18
                        }
                    }
                },
                '@supports (-webkit-touch-callout: none)': {
                    paddingBottom: '90px'
                }
            }
        },
        deleteButton: {
            [theme.breakpoints.down(wbp)]: {
                marginRight: `${reduceResolution(12)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginRight: '12px'
            }
        },
        separator: {
            display: 'none',
            backgroundColor: '#F4F3F0',
            height: '16px',
            width: '110%',
            marginLeft: '-5%',
            [theme.breakpoints.down('md')]: {
                display: 'block'
            }
        },
        deleteMsgMobile: {
            display: 'none',
            [theme.breakpoints.down('md')]: {
                'display': 'flex',
                'marginTop': '20px',
                '& > div': {
                    alignItems: 'center'
                },
                '& p': {
                    fontSize: '14px',
                    marginBottom: '0px'
                },
                '& svg': {
                    fontSize: '14px',
                    marginRight: '10px'
                }
            }
        },
        deleteMsgDesktop: {
            [theme.breakpoints.down('md')]: {
                display: 'none'
            }
        },
        appointmentsHistoryWrapper: {
            [theme.breakpoints.down('sm')]: {
                margin: '0px -16px'
            }
        },
        petInfo: {
            padding: '16px 20px'
        }
    })
);

const petInformationOption = { id: 'pet_information', name: 'Pet Information' };
const medicalHistoryOption = { id: 'medical_history', name: 'Medical History' };
const petNotesOption = { id: 'pet_notes', name: 'Notes' };
const appointmentsHistoryOption = {
    id: 'appointments_history',
    name: 'Appointments History'
};

const viewOptions = [petInformationOption, medicalHistoryOption, petNotesOption, appointmentsHistoryOption];

export const EditPet: FunctionComponent<EditPetProps> = props => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const apiClientWarpper = useApiClientWrapper();

    const savedPet = useSelector<RootState, Pet | undefined>(state => state.pets.savedPet);
    const petStatus = useSelector<RootState, PetsStatus>(state => state.pets.status);

    const vet = useSelector<RootState, Vet | undefined>(state => state.vets.vet || state.vets.savedVet);
    const [petNotesEdited, setPetNotesEdited] = useState(false);

    const [selectedViewId, setSelectedViewId] = useState(
        props.editMedicalHistory
            ? medicalHistoryOption.id
            : props.editPetNotes === true || petNotesEdited === true
            ? petNotesOption.id
            : petInformationOption.id
    );

    const [editPet, setEditPet] = useState(props.pet);
    const [errors, setErrors] = useState<Array<FieldError>>([]);
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [book, setBook] = useState<Book | null>(null);

    useEffect(() => {
        dispatch(resetVet());
        if (props.pet.vetId) {
            dispatch(getVetThunk(apiClientWarpper, props.pet.vetId));
        }

        if (petNotesEdited === true) {
            setSelectedViewId(petNotesOption.id);
            setPetNotesEdited(false);
        }
    }, [props.pet]);

    useNonInitialEffect(() => {
        if (props.pet.uuid !== editPet?.uuid) {
            setEditPet(props.pet);
            setSelectedViewId(petInformationOption.id);
            setShowDeleteConfirmation(false);
            setBook(null);
        }

        if (petNotesEdited === true) {
            setSelectedViewId(petNotesOption.id);
            setPetNotesEdited(false);
        }
    }, [props.pet]);

    useNonInitialEffect(() => {
        switch (petStatus) {
            case PetsStatus.SavePetSuccess:
                setSaving(false);
                setEditPet(savedPet!);
                if (selectedViewId !== petNotesOption.id) {
                    props.onSaved && props.onSaved(savedPet!, false);
                } else {
                    props.onSaved && props.onSaved(savedPet!, true);
                }
                break;
            case PetsStatus.DeleteSuccess:
                setDeleting(false);
                props.onDeleted && props.onDeleted();
                break;
            case PetsStatus.Error:
                setSaving(false);
                break;
        }
    }, [petStatus]);

    const saveHandler = () => {
        const errors = validatePet(editPet);
        setErrors(errors);
        errors.forEach(error => dispatch(showErrorThunk(error.errorMessage)));

        if (errors.length === 0) {
            setSaving(true);
            dispatch(savePetThunk(apiClientWarpper, editPet));
        }
    };

    const deleteHandler = () => {
        setDeleting(true);
        dispatch(deletePetThunk(apiClientWarpper, editPet.id!));
    };

    const bookHandler = () => {
        setBook({
            petId: props.pet.id!,
            clientId: props.pet.clientId,
            clientName: props.pet.clientName || ''
        });
    };

    const changeMedicalHistoryHandler = (medicalHistory: PetMedicalHistory) => {
        setEditPet(prev => ({
            ...prev,
            medicalHistory: cloneDeep(medicalHistory)
        }));
    };

    const changePetNotesHandler = (petNotes: Array<Note>) => {
        const editPetOther = editPet;
        editPetOther.notes = petNotes;
        setEditPet(editPetOther);
        setSelectedViewId(petNotesOption.id);
        setPetNotesEdited(true);
        saveHandler();
    };

    const handleBackClick = () => {
        //removeBodyStyles();
        props.onSelect && props.onSelect();
    };

    const Separator = () => {
        return <Box className={classes.separator}></Box>;
    };

    const CannotBeDeletedMessage = ({ className }: CannotBeDeletedMessageProps) => (
        <Grid xs={12} className={className}>
            <CannotBeDeleted text="This pet cannot be removed because it has at least one appointment." />
        </Grid>
    );

    const petInformation = (
        <Grid item xs={12}>
            <FormSection
                title="Basic Information"
                subtitle="Enter the pet's basic information, personality type, and grooming details."
                column={true}
            >
                <PetForm pet={editPet} errors={errors} onChange={setEditPet} />
            </FormSection>

            <Separator />

            <DeceasedPet pet={editPet} />

            <Separator />

            {!editPet.destroyable && <CannotBeDeletedMessage className={classes.deleteMsgMobile} />}

            <Grid item xs={12} className={classes.footer}>
                {!editPet.destroyable && <CannotBeDeletedMessage className={classes.deleteMsgDesktop} />}
                {editPet.destroyable && (
                    <Button
                        id="clients_delete_pet_button"
                        label="Delete"
                        variant="danger"
                        className={classes.deleteButton}
                        loading={deleting}
                        disabled={saving}
                        onClick={() => setShowDeleteConfirmation(true)}
                    />
                )}
                <Button label="Save" color="orange" loading={saving} disabled={deleting} onClick={saveHandler} />
            </Grid>
        </Grid>
    );

    const medicalHistory = (
        <MedicalHistory
            pet={editPet}
            vet={vet}
            saving={saving}
            onChangeMedicalHistory={changeMedicalHistoryHandler}
            edited={props.editMedicalHistory}
        />
    );

    const petNotes = (
        <PetNotes
            editable={true}
            dateDivider={true}
            pet={editPet}
            title={true}
            saving={saving}
            pagination={true}
            onChangePetNotes={changePetNotesHandler}
            edited={props.editPetNotes}
            overfullWindow={props.overfullWindow}
        />
    );

    useEffect(() => {
        console.log('editPet', { editPet });
    }, [editPet]);

    const bookings = (
        <Box className={classes.appointmentsHistoryWrapper}>
            <BookingsComponentClient clientID={editPet.clientId} petIds={[editPet.id!]} from={'pet'} />
        </Box>
    );

    const newBooking = (
        <CreateBookingDrawer
            parentID={book?.clientId}
            parentName={book?.clientName}
            petID={book?.petId}
            fromClient={true}
            petsLoaded={true}
            onClose={() => setBook(null)}
            onBooked={() => {
                props.onBooked && props.onBooked();
                setEditPet(prev => ({ ...prev, destroyable: false }));
                setBook(null);
            }}
            showTabs={false}
        />
    );

    return (
        <>
            <Grid item xs={12}>
                <Header
                    pet={editPet}
                    hideHeadingComponent={props.hideHeadingComponent}
                    views={props.editMedicalHistory || props.hideTabs ? [] : viewOptions}
                    selectedViewId={selectedViewId}
                    onShow={setSelectedViewId}
                    onBook={props.editMedicalHistory ? undefined : bookHandler}
                    onBack={handleBackClick}
                    onSelect={props.onSelectDropdown}
                    onClikedActions={props.onClikedActions}
                />
                <Grid item xs={12} className={classes.body}>
                    {selectedViewId === petInformationOption.id && petInformation}
                    {selectedViewId === medicalHistoryOption.id && medicalHistory}
                    {selectedViewId === appointmentsHistoryOption.id && bookings}
                    {selectedViewId === petNotesOption.id && petNotes}
                </Grid>
            </Grid>
            {showDeleteConfirmation && (
                <ConfirmDialog
                    id="clients_pet_confirm_dialog_"
                    open={true}
                    title={<Typography>{editPet!.name}</Typography>}
                    question={<Typography>Do you want to delete this Pet?</Typography>}
                    cancelButtonLabel="Cancel"
                    confirmButtonLabel="Yes"
                    onCancel={() => setShowDeleteConfirmation(false)}
                    onConfirm={() => {
                        setShowDeleteConfirmation(false);
                        deleteHandler();
                    }}
                />
            )}
            {book && newBooking}
        </>
    );
};

export default EditPet;
