import { Box, Typography } from '@material-ui/core';
import { FunctionComponent, useState } from 'react';

interface HoverableTextProps {
    primaryText: string;
    secondaryText?: string;
    className?: string;
}

export const HoverableText: FunctionComponent<HoverableTextProps> = props => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <Box onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <Typography className={props.className}>
                {isHovered && props.secondaryText ? props.secondaryText : props.primaryText}
            </Typography>
        </Box>
    );
};

export default HoverableText;
