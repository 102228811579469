import { FunctionComponent, useState } from 'react';

import {
    faArrowDownWideShort,
    faArrowUpWideShort,
    faBarsFilter as faIcon,
    faEyeSlash
} from '@fortawesome/pro-regular-svg-icons';
import { Box, ClickAwayListener, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { reduceResolution, wbp } from 'Theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface FiltersProps {
    onSelect?: (option: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        filterRootContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative'
        },
        filterContainer: {
            [theme.breakpoints.down(wbp)]: {
                paddingTop: `${reduceResolution(15)}px`,
                paddingBottom: `${reduceResolution(15)}px`,
                paddingLeft: `${reduceResolution(25)}px`,
                paddingRight: `${reduceResolution(25)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '15px',
                paddingBottom: '15px',
                paddingLeft: '25px',
                paddingRight: '25px'
            },
            'display': 'flex',
            'alignItems': 'center',
            'justifyContent': 'center',
            'width': '5%',
            'textAlign': 'left',
            'cursor': 'pointer',
            'position': 'relative',
            'borderRadius': '50px',
            '&:hover': {
                'backgroundColor': '#FFF',
                '& + #label': {
                    display: 'flex'
                }
            }
        },
        filterButton: {
            fontWeight: 400,
            fontSize: '18px'
        },
        filterDropdown: {
            paddingTop: '10px',
            paddingBottom: '10px',
            display: 'flex',
            position: 'absolute',
            flexDirection: 'column',
            zIndex: 5,
            borderRadius: 18,
            overflow: 'hidden',
            border: '2px solid #222',
            backgroundColor: 'white',
            boxShadow: '0p 8px 10px rgba(0, 0, 0, 0.08)',
            minHeight: '45px',
            height: 'auto',
            [theme.breakpoints.down(wbp)]: {
                left: `-${reduceResolution(110)}px`,
                top: `${reduceResolution(40)}px`,
                width: `${reduceResolution(170)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                left: '-110px',
                top: '40px',
                width: '170px'
            }
        },
        filterItem: {
            [theme.breakpoints.down(wbp)]: {
                paddingLeft: `${reduceResolution(20)}px`,
                paddingRight: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingLeft: '20px',
                paddingRight: '20px'
            },
            'cursor': 'pointer',
            '& :hover': {
                backgroundColor: '#F8F5F1'
            }
        },
        filterItemText: {
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(14)}px`,
                lineHeight: `${reduceResolution(27)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '14px',
                lineHeight: '27px'
            }
        },
        filterItemTextTest: {
            fontSize: '9px',
            lineHeight: '27px'
        }
    })
);

const filterOptions = [
    { id: 'unread', icon: faEyeSlash, title: 'Unread' },
    { id: 'newest', icon: faArrowDownWideShort, title: 'Sort by Newest' },
    { id: 'oldest', icon: faArrowUpWideShort, title: 'Sort by Old' }
];

const Filters: FunctionComponent<FiltersProps> = props => {
    const [showFilterDropdown, setShowFilterDropdown] = useState(false);
    const classes = useStyles();

    return (
        <Box className={classes.filterRootContainer}>
            <Box className={classes.filterContainer} onClick={() => setShowFilterDropdown(prev => !prev)}>
                <FontAwesomeIcon className={classes.filterButton} icon={faIcon} />
            </Box>
            {showFilterDropdown && (
                <ClickAwayListener onClickAway={() => setShowFilterDropdown(prev => !prev)}>
                    <Box className={classes.filterDropdown}>
                        {filterOptions.map((option, index) => (
                            <Box className={classes.filterItem} key={index}>
                                <Typography
                                    className={classes.filterItemText}
                                    onClick={() => {
                                        props.onSelect && props.onSelect(option.id);
                                        setShowFilterDropdown(false);
                                    }}
                                >
                                    <FontAwesomeIcon icon={option.icon} />
                                    {option.title}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </ClickAwayListener>
            )}
        </Box>
    );
};

export default Filters;
